import { useContext, useEffect, useRef } from "react";
import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../Hooks/useFetch';
import { EditFormContext } from "./EditModForm";

export default function EnteSelect({ disabled, onChange, value, required }) {
	const { data: enti, status } = useFetch('/ente/list', null, 'GET');

	const [state, dispatch] = useContext(EditFormContext) ?? [null, () => {}];
	const previousState = useRef("");

	useEffect(() => {
		if (previousState.current !== status && status === "fetching") dispatch({ type: "AddCounter" });
		if (previousState.current !== status && status === "fetched") dispatch({ type: "DecreaseCounter" });
		if (previousState.current !== "fetching" && status === "idle") dispatch({ type: "ResetCounter" });
		previousState.current = status;
	}, [status]);

	return enti && (
		<Autocomplete
			disablePortal
			autoHighlight
			disabled={disabled}
			id="combo-box-demo"
			className="w-100 mt-2"
			options={enti ? enti.map((ente) => ({
				label: ente.denominazione,
				codiceEnte: ente.codiceEnte
			})) : []}
			isOptionEqualToValue={(option, v) => option.codiceEnte === v}
			onChange={(event, item) => onChange(item?.codiceEnte)}
			value={value || null}
			renderOption={(props, option) => (
				<li {...props} key={option.codiceEnte}>{option.label}</li>
			)}
			renderInput={(fieldParams) => (
				<TextField {...fieldParams} required={required} label="Ente" variant="standard" />
			)}
			ListboxProps={{ style: { maxHeight: '250px' } }}
			noOptionsText="Nessuna Opzione"
		/>
	);
}
