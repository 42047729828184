import React, { useCallback, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useFetch } from "../../Hooks/useFetch";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";

export default function DettaglioMail() {
	const { ente, codice, tributo, id } = useParams();
	const query = codice != null ? `/email/${ente}/${tributo}/${codice}` : `/email/${id}`;
	const { data } = useFetch(query, null, 'GET');
	const [allegatiFetched, setAllegatiFetched] = useState(false);
	const [allegati, setAllegati] = useState([]);

	// - Prendo il token JWT
	const jwt = useToken();

	// - Callback dall'apertura dell'Accordion per gli Allegati
	//   (per evitare di non avere ancora ricevuto i dati dalla Email)
	const getAllegati = useCallback(async () => {
		const response = await fetch(`${configuration.serverAddress}/email/allegati`, {
			method: "POST",
			body: JSON.stringify(data.allegati),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		});

		const jsonResponse = await response.json();
		setAllegati(jsonResponse.data);
	}, [data]);

	const trStyle = {
		background: "rgba(0,0,0,0.05)",
		fontSize: "15px"
	};

	return data && (
		<>
			<Box className="white-bg m-3 p-3 mt-4">
				<div className="d-flex flex-row align-items-center">
					<h2>
						{ codice != null ? `${data.ente.codiceEnte} - ${data.idTributo} - ${data.idDebito}` : `${data.oggetto}`}
					</h2>
				</div>
				<Divider className="mt-3" />
				<div className="d-flex flex-row mt-4 ml-4">
					<div className="d-flex flex-column">
						<h5 className="ml-2">Informazioni generali</h5>
						<table style={{ borderCollapse: "separate", borderSpacing: "0px 2px" }}>
							<tbody>
								<tr style={trStyle}>
									<td className="pl-3"><b>Data Creazione</b></td>
									<td className="pl-3 pr-3">{new Date(data.timestampCreazione).toLocaleString()}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3"><b>Data invio</b></td>
									<td className="pl-3 pr-3">{data.timestampInvio ? new Date(data.timestampInvio).toLocaleString() : "--Non inviata--"}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3"><b>Destinatario</b></td>
									<td className="pl-3 pr-3">{data.emailDestinatario ?? "--Email Assente--"}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3"><b>Oggetto</b></td>
									<td className="pl-3 pr-3">{data.oggetto}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3"><b>Corpo</b></td>
									<td className="pl-3 pr-3">{data.contenuto}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</Box>
			<Accordion className="ml-3 mr-3 shadow" style={{ border: "none" }}>
				<AccordionSummary
					expandIcon={<i className="fas fa-chevron-down" />}
					onMouseDown={() => {
						if (!allegatiFetched) {
							getAllegati().then(() => setAllegatiFetched(true));
						}
					}}
				>
					<h3>Allegati</h3>
				</AccordionSummary>
				<AccordionDetails>
					<ul>
						{React.Children.toArray(allegati.map((x) => <li>{x}</li>))}
					</ul>
				</AccordionDetails>
			</Accordion>
		</>
	);
}
