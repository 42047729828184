/* eslint-disable class-methods-use-this */
import React, { useEffect } from "react";
import {
	TextField, Divider, InputLabel, Select, MenuItem,
	FormControl, Switch, FormControlLabel
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import BasePlugin from "../BasePlugin";

export default class InteressiPlugin extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.interessi.InteressiConfiguration",
			nome: "Interessi legali",
			codicePlugin: "INTERESSI",
			ordine: this.configuration.index,
			descrizione: "Gestisce la configurazione degli interessi legali.",
			tassoFisso: null,
			capitalizzazione: "Nessuna",
			forzaCalcolo: false
		};
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentSubmit({ formValues, pluginData }) {
		return {
			formValues,
			pluginData: [...pluginData.filter((e) => e.codicePlugin !== "INTERESSI" && e.codicePlugin), pluginData?.find((e) => e.codicePlugin === "INTERESSI") ?? {
				codicePlugin: "INTERESSI",
				"@class": "it.ras.pagopa.shared.plugins.pagamenti.interessi.InteressiData"
			}]
		};
	}

	/**
	 * Componente chiamato durante il rendering del form di
	 * configurazione del tributo (backoffice).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @param baseFormPath Nome base degli input da inserire nel form.
	 * @param setValue Setta manualmente un valore del form.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		return (
			<div>
				<Controller name={`${path}.tassoFisso`} render={({ field: { onChange, value } }) => (
					<FormControlLabel className="mt-3" label="Abilita il tasso fisso"
						control={<Switch onChange={(e, v) => onChange(v ? 0.00 : null)} checked={value !== null} />}
					/>
				)} />

				<Controller name={`${path}.forzaCalcolo`} render={({ field: { onChange, value } }) => (
					<FormControlLabel className="mt-3 ml-4" label="Forza il calcolo"
						control={<Switch onChange={(e, v) => onChange(v)} checked={value} />}
					/>
				)} />

				<div className="d-flex flex-row align-middle mt-4">
					<Controller name={`${path}.capitalizzazione`} render={({ field: { onChange, value } }) => (
						<FormControl variant="standard" style={{ width: "250px" }}>
							<InputLabel id="tipoCapi">Tipo capitalizzazione</InputLabel>
							<Select label="Tipo capitalizzazione" value={value} onChange={(v) => onChange(v.target.value)}>
								<MenuItem value="Nessuna" defaultValue>Nessuna</MenuItem>
								<MenuItem value="Trimestrale">Trimestrale</MenuItem>
								<MenuItem value="Semestrale">Semestrale</MenuItem>
								<MenuItem value="Annuale">Annuale</MenuItem>
							</Select>
						</FormControl>
					)} />

					<Controller name={`${path}.tassoFisso`} render={({ field: { onChange, value } }) => value !== null && (
						<TextField label="Tasso fisso" type="number" variant="standard" className="ml-2" value={value} onChange={(e) => onChange(e.target.value)} />
					)} />
				</div>
			</div>
		);
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentFormEditor({ config, basePath }) {
		const { setValue, watch } = useFormContext();

		useEffect(() => {
			setValue(`${basePath}.@class`, "it.ras.pagopa.shared.plugins.pagamenti.interessi.InteressiData");
			setValue(`${basePath}.codicePlugin`, "INTERESSI");
		}, []);

		return (
			<>
				<Controller
					name={`${basePath}.tipo`}
					defaultValue="NessunInteresse"
					render={({ field: { onChange, value } }) => (
						<FormControl variant="standard" style={{ width: "250px" }}>
							<InputLabel id="tipo">Tipo voce</InputLabel>
							<Select labelId="tipo-label" id="tipo" label="Tipo interessi" value={value ?? ''} onChange={onChange}>
								<MenuItem value="NessunInteresse">Nessun interesse</MenuItem>
								<MenuItem value="InteressiSemplici">Interessi semplici</MenuItem>
								<MenuItem value="InteressiComposti">Interessi composti</MenuItem>
							</Select>
						</FormControl>
					)}
				/>
			</>
		);
	}
}
