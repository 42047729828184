/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isTokenExpired } from "../../Utils/jwt";
import configuration from "../../configuration";

const initialState = {
	refreshToken: null,
	accessToken: null,
	user: null,
	state: null
};

export const logout = createAsyncThunk('authentication/logout', async (params, api) => {
	const { authentication: { refreshToken } } = api.getState();

	// - Chiamata API per segnalare il logout all'IDM RAS
	const response = await fetch(`${configuration.serverAddress}/auth/logout`, {
		method: 'GET',
		headers: new Headers({ Authorization: `Bearer ${refreshToken}`, ...configuration.defaultHeaders })
	});
});

export const selectArea = createAsyncThunk('authentication/selectArea', async ({ jwt, id }, api) => {
	// Verifico se il token è scaduto ed effettuo il login
	if (isTokenExpired(jwt)) {
		api.dispatch(logout());
		return { tokens: null, user: null };
	}

	// - Chiamata API per ricevere il nuovo JWT per accedere alla sezione
	const responseJwt = await fetch(`${configuration.serverAddress}/auth/access/${id}`, {
		method: 'GET',
		headers: new Headers({ Authorization: `Bearer ${jwt}`, ...configuration.defaultHeaders })
	});

	if (responseJwt.status !== 200) throw new Error('Errore nella ricezione delle informazioni utente.');

	const tokens = await responseJwt.json();

	// - Chiamata API per ricevere le informazioni dell'utente
	const responseUser = await fetch(`${configuration.serverAddress}/user/info`, {
		method: 'GET',
		headers: new Headers({ Authorization: `Bearer ${tokens.accessToken}`, ...configuration.defaultHeaders })
	});

	if (responseUser.status !== 200) throw new Error('Errore nella ricezione delle informazioni utente.');

	const user = await responseUser.json();

	return { ...tokens, user };
});

export const getRefreshToken = createAsyncThunk('authentication/refreshToken', async (params, api) => {
	const { authentication: { refreshToken } } = api.getState();

	// - Chiamata API per ricevere il nuovo JWT per accedere alla sezione
	const response = await fetch(`${configuration.serverAddress}/auth/refresh`, {
		method: 'POST',
		headers: new Headers({ Authorization: `Bearer ${refreshToken}`, ...configuration.defaultHeaders })
	});

	if (response.status !== 200) {
		api.dispatch(logout());
		throw new Error('Errore nella ricezione delle informazioni utente.');
	}

	return response.json();
});

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		setPassiveToken: (state, { payload: jwt }) => {
			state.accessToken = jwt;
		},
		resetArea: (state, action) => {
			if (state.user) state.user.tipo = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(selectArea.fulfilled, (state, { payload: { refreshToken, accessToken, user } }) => {
				state.refreshToken = refreshToken;
				state.accessToken = accessToken;
				state.user = user;
				state.state = null;
			})
			.addCase(getRefreshToken.pending, (state) => {
				state.state = "refreshing";
			})
			.addCase(getRefreshToken.fulfilled, (state, { payload: { accessToken } }) => {
				state.state = null;
				state.accessToken = accessToken;
			})
			.addCase(getRefreshToken.rejected, (state) => {
				state.state = null;
			})
			.addCase(logout.fulfilled, (state, action) => {
				state.state = null;
				state.accessToken = null;
				state.refreshToken = null;
				state.user = null;
			});
	}
});

export const { setPassiveToken, resetArea } = authenticationSlice.actions;

export default authenticationSlice.reducer;
