import React from "react";
import NumberFormat from "react-number-format";
import { Divider } from "@mui/material";
import { useFetch } from "../../Hooks/useFetch";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import BigDataTable from "../Components/Tables/BigDataTable";
import SpinnyPage from "../Components/SpinnyPage";

export default function Cruscotto({ account }) {
	if (account.tipo === "Admin" || account.tipo === "Interessi Legali") {
		return (
			<div>
				<h1 className="mt-4 ml-3">Admin backoffice</h1>
				<Divider />
			</div>
		);
	}

	const { data } = useFetch('/ente/stats', null, 'GET', null, 1);

	if (!data) return (<SpinnyPage />);

	const stats = {
		importoTotPagamenti: data.statisticheTributi
			.reduce((acc, v) => acc + v.importoTotPagamenti, 0),
		importoTotRendicontati: data.statisticheTributi
			.reduce((acc, v) => acc + v.importoTotRendicontati, 0),
		nomeTributo: (<b>TOTALE</b>),
		numeroPagamenti: data.statisticheTributi
			.reduce((acc, v) => acc + v.numeroPagamenti, 0),
		numeroRendicontati: data.statisticheTributi
			.reduce((acc, v) => acc + v.numeroRendicontati, 0)
	};

	if (typeof data.statisticheTributi?.[data.statisticheTributi.length - 1]?.nomeTributo !== 'object') data.statisticheTributi.push(stats);

	return (
		<div>
			<h1 className="mt-4 ml-3">{data.nomeEnte}</h1>
			<Divider />
			<BigDataTable
				onPageChange={(page) => {}}
				page={0}
				data={data.statisticheTributi}
				pageSize={Infinity}
				totalCount={data.totalResults}
				idField="nomeTributo"
			>
				<DataTableColumn renderHeader={() => "Nome tributo"} renderContent={(r) => r.nomeTributo} />
				<DataTableColumn renderHeader={() => "Pagamenti totali"} renderContent={(r) => r.numeroPagamenti} min />
				<DataTableColumn renderHeader={() => "Importo totale"} renderContent={(r) => <NumberFormat value={r.importoTotPagamenti} prefix="€ " displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." />} min />
				<DataTableColumn renderHeader={() => "Pagamenti eseguiti"} renderContent={(r) => r.numeroRendicontati} min />
				<DataTableColumn renderHeader={() => "Importo ricevuto"} renderContent={(r) => <NumberFormat value={r.importoTotRendicontati} prefix="€ " displayType="text" decimalScale={2} decimalSeparator="," thousandSeparator="." />} min />
			</BigDataTable>
		</div>
	);
}
