import React from "react";
import { TextField } from "@mui/material";

export default function CampoString({ value, onChange, campo }) {
	return (
		<TextField
			type="string"
			variant="standard"
			style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }}
			value={value}
			label={campo.label}
			onChange={(v) => onChange(v.target.value)}
		/>
	);
}
