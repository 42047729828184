/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Divider,
	IconButton
} from "@mui/material";
import NumberFormat from "react-number-format";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useFetch } from "../../Hooks/useFetch";
import SmallDataTable from "../Components/Tables/SmallDataTable";
import DataTableColumn from "../Components/Tables/DataTableColumn";
import { deleteApi } from "../Utils/DeleteApi";
import ConfirmModal from "../Components/ConfirmModal";
import useToken from "../../Hooks/useToken";
import SpinnyPage from "../Components/SpinnyPage";

function StatoPagamentoColor(statoPagamento, riaccreditato) {
	switch (statoPagamento) {
		case "InCorso": return { background: "orange", color: "#572905" };
		case "Eseguito":
			if (!riaccreditato) return { background: "#53befc", color: "#1a243d" };
			return { background: "#6fed7e", color: "#1A3D26" };
		case "NonEseguito": return { background: "#ed6f6f", color: "#3d1a1a" };
		default: return {};
	}
}

function RenderRata({ rata, handleChange, index, expanded, onDelete, altRate, pagamenti }) {
	const [altExpanded, setAltExpanded] = useState(false);

	const altChanged = (panel) => (event, isExpanded) => {
		setAltExpanded(isExpanded ? panel : false);
	};

	const statoPagamento = (r, alt, p, pAlt) => {
		if (p?.stato === "InCorso") return "IN CORSO";
		if (pAlt?.riaccreditato) return "RIACCREDITATA S.A.";
		if (p?.riaccreditato) return "RIACCREDITATA";
		if (pAlt?.stato === "Eseguito") return "PAGATA S.A.";
		if (p?.stato === "Eseguito") return "PAGATA";
		if (p?.stato === "NonEseguito") return "ERR PAGAMENTO";
		return "DA PAGARE";
	};

	const pagamentiCurr = pagamenti?.filter((p) => p.pendenza.altRata === 0);
	const pagamentiAlt = pagamenti?.filter((p) => p.pendenza.altRata > 0);
	const pagamentiLast = [...(pagamentiCurr ?? [])].sort((a, b) => (a.stato === "Eseguito" ? -1 : (b.timestampPagamento - a.timestampPagamento)))[0];
	const pagamentiAltLast = [...(pagamentiAlt ?? [])].sort((a, b) => (a.stato === "Eseguito" ? -1 : (b.timestampPagamento - a.timestampPagamento)))[0];

	return (
		<Accordion expanded={expanded === index} onChange={handleChange(index)} className="ml-3 mr-3 shadow" style={{ border: "none" }}>
			<AccordionSummary>
				<div className="d-flex flex-row align-items-center w-100">
					<div className="pr-3 ml-3">
						<div style={{ fontSize: "14px" }}>Codice Univoco Versamento (IUV)</div>
						<div style={{ fontWeight: "bold", fontSize: "24px", userSelect: "text", cursor: "text" }} onClick={(e) => e.stopPropagation()}>{rata.iuv}</div>
					</div>
					<Divider orientation="vertical" />
					<div className="pr-3 ml-3">
						<div style={{ fontSize: "14px" }}>Inizio validità</div>
						<div style={{ fontWeight: "bold", fontSize: "24px" }}>{moment(rata.inizioValidita).format("DD/MM/yyyy")}</div>
					</div>
					<Divider orientation="vertical" />
					<div className="pr-3 ml-3">
						<div style={{ fontSize: "14px" }}>Data scadenza</div>
						<div style={{ fontWeight: "bold", fontSize: "24px", color: moment(rata.scadenza).isBefore() ? "#db4848" : "inherit" }}>{moment(rata.scadenza).format("DD/MM/yyyy")}</div>
					</div>
					<Divider orientation="vertical" />
					<div className="pr-3 ml-3">
						<div style={{ fontSize: "14px" }}>Fine validità</div>
						<div style={{ fontWeight: "bold", fontSize: "24px", color: moment(rata.fineValidita).isBefore() ? "#db4848" : "inherit" }}>{moment(rata.fineValidita).format("DD/MM/yyyy")}</div>
					</div>
					<Divider orientation="vertical" />
					{
						rata.pagata || rata.pagataAlt ? (
							<div className="mr-1 ml-1 pr-2 pl-2 pt-1 pb-1" style={{ ...StatoPagamentoColor(pagamentiLast?.stato, pagamentiLast?.riaccreditato), borderRadius: "5px" }}>
								<div style={{ fontSize: "14px" }}>Stato pagamento</div>
								<div style={{ fontWeight: "bold", fontSize: "20px", lineHeight: "20px" }}>{statoPagamento(rata, (altRate?.length > 0 || rata.altRata > 0), pagamentiLast, pagamentiAltLast)}</div>
								<div style={{ fontWeight: "bold", fontSize: "10px", lineHeight: "10px" }}>{pagamentiLast && `PAGATA IL ${moment(pagamentiLast.timestampPagamento).format("DD/MM/yyyy")}`}</div>
							</div>
						) : (
							<div className="pr-3 ml-3">
								<div style={{ fontSize: "14px" }}>Stato pagamento</div>
								<div style={{ fontWeight: "bold", fontSize: "24px" }}>{statoPagamento(rata, (altRate?.length > 0 || rata.altRata > 0), pagamentiLast, pagamentiAltLast)}</div>
							</div>
						)
					}
					<Divider orientation="vertical" />
					<div className="pr-3 ml-3" style={{ marginRight: "auto" }}>
						<div style={{ fontSize: "14px" }}>Importo</div>
						<div style={{ fontWeight: "bold", fontSize: "24px" }}>
							<NumberFormat
								value={rata.dettagli.reduce((accVoce, v) => accVoce + v.importo, 0)}
								prefix="€ "
								displayType="text"
								decimalScale={2}
								fixedDecimalScale
								decimalSeparator=","
								thousandSeparator="."
							/>
						</div>
					</div>
					{
						onDelete && (
							<div style={{ marginLeft: "auto" }}>
								<IconButton onClick={onDelete}><i className="fa fa-trash" /></IconButton>
							</div>
						)
					}
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<div className="m-1 p-2 pl-4" style={{ background: "rgba(0,0,0,0.05", fontSize: "18px" }}>
					<span style={{ opacity: 0.5, fontSize: "16px" }}>Causale rata</span>
					<br />
					{rata.causale}
				</div>
				<SmallDataTable
					page={0}
					data={rata.dettagli}
					pageSize={Infinity}
					totalCount={rata.dettagli.length}
					idField="codiceVoce"
				>
					<DataTableColumn renderHeader={() => "Tipo"} renderContent={(r) => r.tipo} min />
					<DataTableColumn renderHeader={() => "Codice voce"} renderContent={(r) => r.codiceDettaglio} min />
					<DataTableColumn
						renderHeader={() => "Importo"}
						renderContent={(r) => (
							<NumberFormat
								value={r.importo}
								prefix="€ "
								displayType="text"
								decimalScale={2}
								fixedDecimalScale
								decimalSeparator=","
								thousandSeparator="."
							/>
						)}
						min
					/>
					<DataTableColumn renderHeader={() => "Descrizione"} renderContent={(r) => r.descrizione} />
					<DataTableColumn renderHeader={() => "Capitolo entrata"} renderContent={(r) => r.capitoloEntrata} min />
					<DataTableColumn renderHeader={() => "Beneficiario"} renderContent={(r) => r.beneficiario} min />
					<DataTableColumn renderHeader={() => "Pos. Accertamento"} renderContent={(r) => r.posizioneAccertamento} min />
					<DataTableColumn renderHeader={() => "# Accertamento"} renderContent={(r) => r.posizioneAccertamento} min />
				</SmallDataTable>

				{
					pagamenti?.length > 0 && (
						<>
							<br />
							<h5 className="ml-3">Transazioni</h5>
							{
								pagamenti.map((pagamento, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<div key={i} style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", borderRadius: "5px", alignItems: "center" }} className="mt-2 mb-2 ml-3 mr-3 d-flex flex-row">
										<div style={{ ...StatoPagamentoColor(pagamento?.stato, pagamento?.riaccreditato), width: "6px", height: "35px", borderRadius: "5px 0 0 5px" }} />
										<div style={{ fontSize: "10px", fontWeight: "bold", textTransform: "uppercase", columnGap: "10px", alignItems: "center" }} className="pl-2 pr-2 d-flex flex-row">
											<div className="pb-1">
												<div style={{ opacity: 0.7 }}>ID Pagamento</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>{`#${pagamento.id}`}</div>
											</div>
											<Divider orientation="vertical" style={{ height: "25px" }} />
											<div className="pb-1">
												<div style={{ opacity: 0.7 }}>Data operazione</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>
													{moment(pagamento.timestampPagamento).format("DD/MM/yyyy HH:mm")}
												</div>
											</div>
											<Divider orientation="vertical" style={{ height: "25px" }} />
											<div className="pb-1" style={{ minWidth: "140px" }}>
												<div style={{ opacity: 0.7 }}>Stato</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>
													{statoPagamento(rata, (altRate?.length > 0 || rata.altRata > 0), pagamento, null)}
												</div>
											</div>
											<Divider orientation="vertical" style={{ height: "25px" }} />
											<div className="pb-1">
												<div style={{ opacity: 0.7 }}>Importo</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>
													<NumberFormat
														value={pagamento.importo}
														prefix="€ "
														displayType="text"
														decimalScale={2}
														fixedDecimalScale
														decimalSeparator=","
														thousandSeparator="."
													/>
												</div>
											</div>
											<Divider orientation="vertical" style={{ height: "25px" }} />
											<div className="pb-1">
												<div style={{ opacity: 0.7 }}>Versante</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>
													{pagamento.transazione.versante.codiceFiscale ?? 'N.D.'}
												</div>
											</div>
											<Divider orientation="vertical" style={{ height: "25px" }} />
											<div className="pb-1">
												<div style={{ opacity: 0.7 }}>ID flusso</div>
												<div style={{ fontSize: "15px", lineHeight: "15px" }}>
													{pagamento?.versamenti?.[0]?.sospeso?.rendicontazione?.identificativoFlusso ?? (pagamento?.stato === "Eseguito" ? "ATTESA RENDICONTAZIONE" : "NON PAGATO")}
												</div>
											</div>
										</div>
									</div>
								))
							}
						</>
					)
				}

				{
					altRate?.length > 0 && (
						<>
							<h4 className="ml-4 mt-5">Soluzioni alternative</h4>
							{
								altRate.map((altRata, idx) => (
									<RenderRata
										key={altRata.numeroRata}
										altRate={[]}
										rata={altRata}
										index={altRata.numeroRata}
										expanded={altExpanded}
										handleChange={altChanged}
									/>
								))
							}
						</>
					)
				}
			</AccordionDetails>
		</Accordion>
	);
}

function tipoRate(data) {
	if (data.pendenze.length > 1 && data.pendenze.filter((p) => p.numeroRata === 0).length > 0) return "Soluzione Unica + Rate";
	if (data.pendenze.length === 1 && data.pendenze.filter((p) => p.numeroRata === 0).length > 0) return "Soluzione unica";
	return "Rate";
}

export default function DettaglioDebito({ account }) {
	const [refresh, setRefresh] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [deleteDebit, setDeleteDebit] = useState(null);
	const [deleteState, setDeleteState] = useState('idle');

	const { ente, codice, tributo, altDebito } = useParams();

	// - Decodifica dei path dall'url rimuovendo gli escape
	const codiceDecoded = decodeURIComponent(decodeURIComponent(codice));
	const enteDecoded = decodeURIComponent(decodeURIComponent(ente));
	const tributoDecoded = decodeURIComponent(decodeURIComponent(tributo));

	const { data } = useFetch(`/debiti/${ente}/${tributo}/${codice}/${altDebito ?? 0}`, null, 'GET', null, refresh);
	const { data: pagamentiData } = useFetch(`/pagamento/query`, null, 'POST', {
		pendenza: {
			debito: {
				altDebito: altDebito ?? 0,
				codiceDebito: codiceDecoded,
				ente: { codiceEnte: enteDecoded },
				tributo: { codiceTributo: tributoDecoded }
			}
		},
		pageSize: 2000
	}, refresh);

	const canModify = ["All", "GestioneDebiti"].some((p) => account.ruolo.permessi.includes(p));

	// - Prendo il token JWT
	const jwt = useToken();

	const history = useHistory();

	const deleteConfirmCallback = async () => {
		setDeleteState('loading');

		let delteData = {};
		if (deleteDebit === "debito") {
			delteData = {
				...data,
				pendenze: undefined
			};
		} else {
			delteData = {
				...data,
				pendenze: [data.pendenze.find((p) => p.numeroRata === deleteDebit)]
			};
		}

		const result = deleteDebit === "debito"
			? await deleteApi("/debiti/delete", [delteData], ente, jwt)
			: await deleteApi("/debiti/delete", [delteData], ente, jwt);

		if (result) {
			setDeleteState('idle');
			setDeleteDebit(null);
			setRefresh(refresh + 1);

			if (deleteDebit === "debito") {
				history.goBack();
			}
		} else {
			setDeleteState('idle');
		}
	};

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const trStyle = {
		background: "rgba(0,0,0,0.05)",
		fontSize: "15px"
	};

	if (!data && !pagamentiData) return <SpinnyPage />;

	return data && (
		<>
			<ConfirmModal
				open={deleteDebit !== null}
				loading={deleteState === 'loading'}
				title="Conferma eliminazione"
				text={deleteDebit === "debito" ? `Sei sicuro di voler eliminare il debito?` : `Sei sicuro di voler eliminare la rata?`}
				onConfirm={deleteConfirmCallback}
				onDeny={() => setDeleteDebit(null)}
				confirmText="Elimina"
			/>
			<Box className="white-bg m-3 p-3 mt-4">
				<div className="d-flex flex-row align-items-center">
					<div>
						<h1>{data.codiceDebito}</h1>
						{`${data.ente.intestatario.denominazione} - ${data.tributo.denominazione}`}
					</div>
					{
						canModify && !data.pendenze.some((p) => p.pagata || p.inCorso) && (
							<div style={{ marginLeft: "auto" }}>
								<IconButton onClick={() => setDeleteDebit("debito")}><i className="fa fa-trash" /></IconButton>
							</div>
						)
					}
				</div>
				<Divider className="mt-3" />

				<div className="d-flex flex-row mt-4 ml-4">
					<div className="d-flex flex-column">
						<h5 className="ml-2">Informazioni generali</h5>
						<table style={{ borderCollapse: "separate", borderSpacing: "0px 2px" }}>
							<tbody>
								<tr style={trStyle}>
									<td className="pl-3">Data emissione</td>
									<td className="pl-3 pr-3">{moment(data.inserimento).format("DD/MM/yyyy")}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Anno di riferimento</td>
									<td className="pl-3 pr-3">{data.annoRiferimento}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Soluzione pagamento</td>
									<td className="pl-3 pr-3">{tipoRate(data)}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">ID Debito</td>
									<td className="pl-3 pr-3">{data.codiceDebito}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Tipologia di pagamento</td>
									<td className="pl-3 pr-3">{data.tributo.codiceTributo}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Beneficiario principale</td>
									<td className="pl-3 pr-3">{data.ente.codiceEnte}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="d-flex flex-column ml-4">
						<h5 className="ml-2">Debitore</h5>
						<table style={{ borderCollapse: "separate", borderSpacing: "0px 2px" }}>
							<tbody>
								<tr style={trStyle}>
									<td className="pl-3">Nome e cognome / Denominazione</td>
									<td className="pl-3 pr-3">{`${data.anagraficaDebitore.nome ?? ""} ${data.anagraficaDebitore.cognome ?? ""} ${data.anagraficaDebitore.ragioneSociale ?? ""} ${data.altDebito > 0 ? "(Debitore alternativo)" : ""}`}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Codice fiscale / P.IVA</td>
									<td className="pl-3 pr-3">{data.anagraficaDebitore.codiceFiscale ?? data.anagraficaDebitore.partitaIva}</td>
								</tr>
							</tbody>
						</table>

						<h5 className="ml-2 mt-4">Causale</h5>
						<table style={{ borderCollapse: "separate", borderSpacing: "0px 2px" }}>
							<tbody>
								<tr style={trStyle}>
									<td className="pl-3 pr-3" style={{ maxWidth: "300px" }}>
										{data.causale}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="d-flex flex-column ml-4">
						<h5 className="ml-2">Pagamento</h5>
						<table style={{ borderCollapse: "separate", borderSpacing: "0px 2px" }}>
							<tbody>
								<tr style={trStyle}>
									<td className="pl-3">Importo totale</td>
									<td className="pl-3 pr-3">
										<NumberFormat
											value={data.pendenze.filter((p) => p.numeroRata === 0).length > 0
												? data.pendenze.filter((p) => p.numeroRata === 0)[0]?.dettagli.reduce((accVoce, v) => accVoce + v.importo, 0)
												: data.pendenze.filter((p) => p.numeroRata > 0).reduce((acc, p) => acc + p.dettagli.reduce((accVoce, v) => accVoce + v.importo, 0), 0)
											}
											prefix="€ "
											displayType="text"
											decimalScale={2}
											fixedDecimalScale
											decimalSeparator=","
											thousandSeparator="."
										/>
									</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Rateizzato</td>
									<td className="pl-3 pr-3">{data.pendenze.filter((p) => p.altRata === 0).length > 1 ? "Si" : "No"}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Numero di rate</td>
									<td className="pl-3 pr-3">{data.pendenze.filter((r) => r.numeroRata > 0).length}</td>
								</tr>
								<tr style={trStyle}>
									<td className="pl-3">Stato</td>
									<td className="pl-3 pr-3">{data.pagato ? "Pagato" : data.pendenze.some((x) => x.inCorso) ? "In Corso" : "Da pagare"}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</Box>

			{
				data.pendenze.filter((p) => !p.altRata && p.numeroRata === 0).length > 0 && (
					<>
						<h2 className="ml-4 mt-5">Soluzione unica</h2>
						<RenderRata rata={data.pendenze.filter((p) => !p.altRata && p.numeroRata === 0)[0]} altRate={data.pendenze.filter((p) => p.numeroRata === 0 && p.altRata > 0)} pagamenti={pagamentiData?.data?.filter((p) => p.pendenza.numeroRata === 0) ?? []} index="solUnica" expanded={expanded} handleChange={handleChange} />
					</>
				)
			}

			{
				data.pendenze.filter((p) => p.numeroRata > 0).length > 0 && (
					<>
						<h2 className="ml-4 mt-5">Rate</h2>
						{
							data.pendenze.filter((p) => p.numeroRata > 0).map((rata, index) => (
								<RenderRata
									key={rata.numeroRata}
									rata={rata}
									altRate={data.pendenze.filter((p) => p.numeroRata === rata.numeroRata && p.altRata !== 0)}
									pagamenti={pagamentiData?.data.filter((p) => p.pendenza.numeroRata === rata.numeroRata) ?? []}
									index={rata.numeroRata}
									expanded={expanded}
									handleChange={handleChange}
									onDelete={(!canModify || rata.pagata || rata.inCorso)
										? null
										: () => setDeleteDebit(rata.numeroRata)}
								/>
							))
						}
					</>
				)
			}

		</>
	);
}
