import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../Hooks/useFetch';
import configuration from "../../../configuration";
import useToken from "../../../Hooks/useToken";

export default function TributoSelect({ disabled, onChange, value, required, ente, ricerca }) {
	const { data: tributi } = useFetch(`/tributo/${ente}/list`, null, 'GET');
	const jwt = useToken();

	return tributi && (
		<Autocomplete
			disablePortal
			autoHighlight
			disabled={disabled}
			id="combo-box-demo"
			className="w-100 mt-2"
			options={tributi ? tributi.data.map((tributo) => ({
				label: tributo.denominazione,
				codiceTributo: tributo.codiceTributo
			})) : []}
			multiple={ricerca} //Selezione multipla abilitata solo quando ricerca è true
			getOptionLabel={(option) => option?.codiceTributo || ""}
			isOptionEqualToValue={(option, v) => v.codiceTributo === option.codiceTributo}
			onChange={async (event, item) => {
				if (!item) {
					onChange([]);
					return;
				}
				onChange(item);
			}}
			value={value ?? []}
			renderOption={(props, option) => (
				<li {...props} key={option?.codiceTributo}>{option?.label}</li>
			)}
			renderInput={(fieldParams) => (
				<TextField {...fieldParams} required={required} label="Tipologia Pagamento" variant="standard" />
			)}
			ListboxProps={{ style: { maxHeight: '250px' } }}
			noOptionsText="Nessuna Opzione"
		/>
	);
}
