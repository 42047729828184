import update from "immutability-helper";
import {
	FETCH_ALL_ENTI_LOADING,
	FETCH_ALL_ENTI_SUCCESS, FETCH_ALL_STRUTTURE_LOADING,
	FETCH_ALL_STRUTTURE_SUCCESS, FETCH_ALL_TRIBUTI_LOADING,
	FETCH_ALL_TRIBUTI_SUCCESS
} from "./actionTypes";

export const initialState = {
	listaEnti: {
		data: null,
		fetchEnded: false
	},
	listaTributi: {
		data: null,
		fetchEnded: false
	},
	listaStrutture: {
		data: null,
		fetchEnded: false
	}
};

const fetchAllEntiSuccessHandler = (state, action) => update(state, {
	listaEnti: {
		data: { $set: action.enti },
		fetchEnded: { $set: true }
	}
});

const fetchAllTributiSuccessHandler = (state, action) => update(state, {
	listaTributi: {
		data: { $set: action.tributi },
		fetchEnded: { $set: true }
	}
});

const fetchAllStruttureSuccessHandler = (state, action) => update(state, {
	listaStrutture: {
		data: { $set: action.strutture },
		fetchEnded: { $set: true }
	}
});

export const ricercaReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_ENTI_LOADING:
			return { ...state, listaEnti: { ...state.listaEnti, fetchEnded: false } };
		case FETCH_ALL_TRIBUTI_LOADING:
			return { ...state, listaTributi: { ...state.listaTributi, fetchEnded: false } };
		case FETCH_ALL_STRUTTURE_LOADING:
			return { ...state, listaStrutture: { ...state.listaStrutture, fetchEnded: false } };
		case FETCH_ALL_ENTI_SUCCESS:
			return fetchAllEntiSuccessHandler(state, action);
		case FETCH_ALL_TRIBUTI_SUCCESS:
			return fetchAllTributiSuccessHandler(state, action);
		case FETCH_ALL_STRUTTURE_SUCCESS:
			return fetchAllStruttureSuccessHandler(state, action);
		default:
			return state;
	}
};
