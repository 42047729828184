import React from "react";
import moment from "moment";
import { Button } from "design-react-kit";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { useFetch } from "../../Hooks/useFetch";
import { readVersion } from "../../Store/LocalState";


export default function Patchnotes() {
	const dispatch = useDispatch();
	const readedVersion = useSelector((state) => state.localState.readedVersion);
	const { data: patchnotes } = useFetch(`/patchnotes/query`, null, 'POST', { orderBy: [{ field: "dataRilascio", desc: true }] });

	const patchnotesGroups = patchnotes && patchnotes.reduce((acc, v) => {
		if (!acc[v.versione]) acc[v.versione] = [];
		acc[v.versione].push(v);
		return acc;
	}, {});

	return (
		<div className="m-4">
			<h1 className="mt-4">Versioni del software</h1>
			<Divider />

			<p className="mt-3" style={{ color: "#888" }}>
				È possibile consultare gli aggiornamenti apportati al sistema nelle ultime versioni di pagoPA Sardegna.
				Verranno inseriti solamente gli aggiornamenti che hanno impatto sull&apos;utilizzo del sistema da parte
				degli operatori. È possibile nascondere l&apos;avviso di nuove funzionalità premendo il bottone &quot;Preso nota delle novità&quot;.
			</p>

			<Button
				onClick={() => dispatch(readVersion(patchnotes[0]?.versione))}
				disabled={!(patchnotes?.length) || patchnotes[0].versione <= readedVersion}
				color="primary"
			>
				Preso nota delle novità
			</Button>

			<div className="d-flex flex-column" style={{ columnGap: "20px", marginTop: "30px" }}>
				{patchnotesGroups && Object.keys(patchnotesGroups).sort((a, b) => b.localeCompare(a)).map((k) => (
					<div className="d-flex flex-row align-items-stretch">
						<div className="d-flex flex-column align-items-center" style={{ borderRight: "1px solid #BBB", paddingRight: "30px", paddingLeft: "30px" }}>
							<span style={{ fontSize: "45px", color: "#1d2d7e", fontWeight: 300, lineHeight: "40px" }}>{moment(patchnotesGroups[k][0].dataRilascio).date().toString().padStart(2, '0')}</span>
							<span style={{ fontSize: "18px", fontWeight: "bold", color: "#1d2d7e", whiteSpace: "nowrap" }}>
								{`${moment(patchnotesGroups[k][0].dataRilascio).format('MMMM')} ${moment(patchnotesGroups[k][0].dataRilascio).format('YY')}`}
							</span>
							{(!readedVersion || readedVersion < patchnotesGroups[k][0].versione) && <span style={{ fontSize: "12px", background: "#1d2d7e", color: "white", borderRadius: "15px", fontWeight: 600, padding: "2px 12px" }}>Novità</span>}
						</div>
						<div className="d-flex flex-column" style={{ paddingLeft: "20px", paddingBottom: "50px" }}>
							{patchnotesGroups[k].sort((a, b) => a.ordine - b.ordine).map((v) => (
								<div className="d-flex flex-column" style={{ marginBottom: "20px" }}>
									<span style={{ fontWeight: 600 }}>{`${v.ordine}. ${v.titolo}`}</span>
									<span style={{ color: "#777" }}>{v.descrizione}</span>
								</div>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
