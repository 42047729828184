import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Profilo from '../Components/Profilo/Profilo';
import Carrello from '../Components/Profilo/Carrello';
import DatiAnagrafici from '../Components/Profilo/DatiAnagrafici';
import RequireAuth from "../Components/Common/RequireAuth";
import PosizioneDebitoria from '../Components/Profilo/PosizioneDebitoria';
import StoricoPagamenti from '../Components/Profilo/StoricoPagamenti';
import PosizioneDebitoriaSpontanea from "../Components/Profilo/PosizioneDebitoriaSpontanea";

export default function PaginaProfilo() {
	const { path, url } = useRouteMatch();

	return (
		<Switch>
			<Route exact path={url}>
				<RequireAuth><Profilo /></RequireAuth>
			</Route>
			<Route exact path={`${url}/dati-anagrafici`}>
				<RequireAuth><DatiAnagrafici /></RequireAuth>
			</Route>
			<Route exact path={`${url}/carrello`}>
				<Carrello />
			</Route>
			<Route exact path={`${url}/posizione-debitoria`}>
				<RequireAuth><PosizioneDebitoria /></RequireAuth>
			</Route>
			<Route exact path={`${url}/posizione-debitoria-spontanea`}>
				<RequireAuth><PosizioneDebitoriaSpontanea /></RequireAuth>
			</Route>
			<Route exact path={`${url}/storico-pagamenti`}>
				<RequireAuth><StoricoPagamenti /></RequireAuth>
			</Route>
			<Route path="*">
				<RequireAuth><Profilo /></RequireAuth>
			</Route>
		</Switch>
	);
}
