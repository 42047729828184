import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Modal,
	TextField,
	Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import StyledDropzone from "./StyledDropzone";

export default function ModalMail(props) {
	const { open, setOpen, onSubmit, onDeny, loading, addEmail } = props;
	const { register, handleSubmit, control } = useForm({
		defaultValues: {
			emailDest: "",
			mailObject: "Avviso di Pagamento",
			mailBody: "Trova in allegato l'avviso di pagamento.",
			fileName: "Avviso [codFis]-[codDeb]"
		}
	});

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	return (
		<Modal
			open={open}
		>
			<Box sx={style}>
				<form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }}>
					<div className="row">
						<div className="col">
							<h4>Compila Email</h4>
							{/* eslint-disable-next-line */}
							{ addEmail && (<TextField {...register("emailDest")} label="Email del destinatario" fullWidth className="mt-2 mb-2" />)}
							<TextField {...register("mailObject")} label="Oggetto della mail" fullWidth className="mt-2 mb-2" required />
							<TextField
								{...register("mailBody")}
								label="Corpo della mail"
								multiline
								minRows={4}
								maxRows={5}
								fullWidth
								className="mt-2 mb-4"
								required
							/>
						</div>
						<div className="col">
							<h4>Formato nome avviso</h4>
							<TextField {...register("fileName")} label="Scegli il formato del nome degli Avvisi in download." fullWidth className="mt-2 mb-2" required />
							<Accordion style={{ backgroundColor: "#f2f2f2" }} className="mt-2 mb-4">
								<AccordionSummary
									expandIcon={<i className="fas fa-chevron-down" />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Typography>Istruzioni di compilazione</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>
										Per inserire dei dati all&apos;interno del nome dei file,
										utilizza i codici seguenti.
										<ul>
											<li>Codice Fiscale: [codFis]</li>
											<li>Denominazione Utente: [denUtente]</li>
											<li>Denominazione Ente: [denEnte]</li>
											<li>Codice Debito: [codDeb]</li>
											<li>Causale: [causale]</li>
											<li>Data Inserimento: [dataIns]</li>
											<li>Data Inizio Validità: [dataInizioVal]</li>
										</ul>
									</Typography>
								</AccordionDetails>
							</Accordion>
							<h4>Allegati PDF</h4>
							<Controller
								render={({ field: { onChange, value } }) => (
									<StyledDropzone
										onChange={onChange}
										value={value}
										accept="application/pdf"
										text="Premere per selezionare o trascinare un file pdf nel box."
									/>
								)}
								name="allegati"
								control={control}
							/>
							<LoadingButton className="mt-4 mb-2" type="submit" fullWidth variant="contained" loading={loading}>CONFERMA</LoadingButton>
							<Button
								className="mb-2"
								onClick={() => {
									onDeny();
									setOpen(false);
								}}
								fullWidth
								variant="outlined"
							>
								Annulla
							</Button>
						</div>
					</div>
				</form>
			</Box>
		</Modal>
	);
}
