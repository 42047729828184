/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
import { Link, useHistory } from 'react-router-dom';
import styles from './Header.module.css';
import Logo from './Logo';
import useToken from "../../Hooks/useToken";

export default function Header({ anagrafica, link }) {
	// - Gestione della navigazione
	const history = useHistory();

	const goToLoginArea = () => history.push(`/autenticazione/cambiaRuolo`);
	const logout = () => history.push('/autenticazione/logout');

	return (
		<header className={`${styles.header} text-white bg-primary d-flex flex-row`}>
			{link
				? <Link to={link}><Logo /></Link>
				: <Logo />}
			<div className="p-3 pr-5 ml-auto d-flex flex-row align-items-center">
				<div><button type="button" onClick={logout} className={`${styles.button} text-white border-0 p-2 pr-3 pl-3 mr-2 rounded`}><i className="fas fa-power-off" /></button></div>
				<div><button type="button" onClick={goToLoginArea} className={`${styles.button} text-white border-0 p-2 pr-3 pl-3 mr-2 rounded`}><i className="fas fa-exchange-alt" /></button></div>
				<div className="d-flex flex-column">
					<div style={{ fontSize: "larger" }}><b>{anagrafica.nome} {anagrafica.cognome}</b></div>
				</div>
			</div>
		</header>
	);
}
