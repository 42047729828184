/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Controller } from "react-hook-form";
import { Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { useFetch } from "../../Hooks/useFetch";
import ToolsArea from "../Components/ToolsArea";
import EnteSelect from "../Components/Forms/EnteSelect";
import RangePicker from "../Components/Forms/RangePicker";
import SpinnyPage from "../Components/SpinnyPage";
import DataGrid from "../Components/Tables/DataGrid";
import useTableColumns from "../Utils/useTableColumns";
import GenerateReportModal from "../Components/GenerateReportModal";
import configuration from "../../configuration";
import useToken from "../../Hooks/useToken";
import ConfirmModal from "../Components/ConfirmModal";

export default function Reportistica({ account }) {
	const enteOperatore = account.ente?.codiceEnte;
	const jwt = useToken();

	const [refresh, setRefresh] = useState(0);
	const [modalOpen, setModalOpen] = useState(false);
	const [reportToDelete, setReportToDelete] = useState(null);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [openFailDelete, setOpenFailDelete] = useState(false);
	const [openFailCreate, setOpenFailCreate] = useState(false);
	const [query, setQuery] = useState({
		page: 0,
		pageSize: 20,
		oggetto: "Reportistica",
		idOperatore: account.tipo === "Admin" ? undefined : account.id
	});
	const [generateReportStatus, setGenerateReportStatus] = useState(false);

	const { data: reports, status: reportsStatus } = useFetch('/reportistica/query', enteOperatore, 'POST', query, refresh);

	const generateReport = async (formData) => {
		setGenerateReportStatus(true);

		const filters = JSON.stringify({
			codiceEnte: enteOperatore ?? formData.ente,
			inizioTsGenerazione: formData.inizioTsGenerazione,
			fineTsGenerazione: formData.fineTsGenerazione,
			tributi: formData?.tributi?.map((x) => ({ codiceEnte: x.codiceEnte, codiceTributo: x.codiceTributo })) ?? [],
			reportAdmin: !enteOperatore
		});

		await fetch(`${configuration.serverAddress}/reportistica/new`, {
			method: 'POST',
			body: filters,
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			setGenerateReportStatus(false);
			setModalOpen(false);
			setRefresh(refresh + 1);
			if (response.status !== 200) setOpenFailCreate(true);
		});
	};

	const downloadPdf = async (idReport) => {
		const response = await fetch(`${configuration.serverAddress}/reportistica/downloadPdf/${idReport}`, {
			method: 'POST',
			body: null,
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	const downloadCsv = async (idReport) => {
		const response = await fetch(`${configuration.serverAddress}/reportistica/downloadCsv/${idReport}`, {
			method: 'POST',
			body: null,
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	const deleteReport = async (idReport) => {
		if (idReport == null) {
			setOpenConfirm(false);
			setOpenFailDelete(true);
			return;
		}
		await fetch(`${configuration.serverAddress}/reportistica/delete/${idReport}`, {
			method: 'DELETE',
			body: null,
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			setReportToDelete(null);
			setOpenConfirm(false);
			setRefresh(refresh + 1);
			if (response.status !== 200) setOpenFailDelete(true);
		});
	};

	const { visibleColumns, allColumns, setVisible, visible, setOrder, sort, sortFilter, setSort } = useTableColumns("reportistica", [
		{
			key: "elimina",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 50,
			width: 50,
			formatter: ({ row }) => <Tooltip title="Elimina Report"><IconButton style={{ fontSize: "20px" }} onClick={() => { setReportToDelete(row.idReport); setOpenConfirm(true); }}><i className="fa fa-trash" /></IconButton></Tooltip>
		},
		{
			key: "downloadPdf",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 50,
			width: 50,
			formatter: ({ row }) => <Tooltip title="Download PDF"><IconButton style={{ fontSize: "20px" }} onClick={() => downloadPdf(row.idReport)}><i className="fa fa-file-pdf" /></IconButton></Tooltip>
		},
		{
			key: "downloadCsv",
			name: "",
			frozen: true,
			draggable: false,
			resizable: false,
			minWidth: 50,
			width: 50,
			formatter: ({ row }) => <Tooltip title="Download CSV"><IconButton style={{ fontSize: "20px" }} onClick={() => downloadCsv(row.idReport)}><i className="fa fa-file-csv" /></IconButton></Tooltip>
		},
		{ key: "idReport", name: "Id Report", sortColumn: "idReport", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 100, width: 100 },
		!enteOperatore && { key: "codiceEnte", name: "Ente", sortColumn: "codiceEnte", sortable: true, frozen: false /*true*/, draggable: false, resizable: true, highlight: true, minWidth: 200, width: 240 },
		{ key: "nomeReport", name: "Nome Report", sortColumn: "nomeReport", sortable: true, frozen: false, draggable: true, highlight: true, minWidth: 260, resizable: true },
		{ key: "dataGenerazione", name: "Data Generazione", sortColumn: "tsGenerazione", sortable: true, draggable: true, resizable: true, highlight: true, minWidth: 180, width: 180 }
	], ["elimina", "downloadPdf", "downloadCsv", "codiceEnte", "idReport", "dataGenerazione", "nomeReport"]);

	useEffect(() => setQuery((q) => ({ ...q, orderBy: sortFilter })), [JSON.stringify(sortFilter)]);

	const tableData = reports?.data?.map((r) => ({
		codiceEnte: r.idEnte,
		idReport: r.idReport.toString() ?? "",
		dataGenerazione: moment(r.tsGenerazione).format("DD/MM/yyyy HH:mm:SS") ?? "",
		nomeReport: r.nomeReport ?? ""
	}));

	if (!reports) return (<SpinnyPage />);

	const FilterFields = [
		[
			() => <Divider><h3>Informazioni generali</h3></Divider>
		],
		[
			...(enteOperatore ? [] : [(r, c) => (
				<div style={{ width: "300px" }}>
					<Controller render={({ field: { onChange, value } }) => (<EnteSelect onChange={onChange} value={value} />)} name="idEnte" control={c} />
				</div>
			)])
		],
		[
			(r) => (<TextField {...r('idReport')} label="Id" variant="standard" style={{ width: "100px" }} />),
			(r) => (<TextField {...r('nomeReport')} label="Nome Report" variant="standard" style={{ width: "300px" }} />)
		],
		[
			() => <Divider className="mt-3" />
		],
		[
			(r, c) => (<RangePicker control={c} nameStart="inizioTsGenerazione" nameEnd="fineTsGenerazione" variant="standard" startText="Creazione da" endText="Creazione a" />)
		]
	];

	return (
		<div>
			<ToolsArea
				className="mt-4 mb-3"
				queryParameters={[
					'idEnte', 'idReport', 'nomeReport', "tsGenerazione"
				]}
				disableDelete
				disableEdit
				disableNew
				filtersChanged={(filters) => setQuery({ ...query, ...filters })}
				fields={FilterFields}
				callStatus={reportsStatus}
				allColumns={allColumns}
				visibleColumns={visible}
				setVisibleColumns={setVisible}
				reportCallback={() => setModalOpen(true)}
			/>
			<GenerateReportModal
				open={modalOpen}
				onDeny={() => setModalOpen(false)}
				callback={generateReport}
				generateStatus={generateReportStatus}
				enteOperatore={enteOperatore}
			/>
			<ConfirmModal
				open={openConfirm}
				title="Eliminazione Report"
				text="Sei sicuro di voler eliminare definitivamente questo Report? Non sarà più possibile effettuare il download del PDF o CSV."
				onConfirm={() => deleteReport(reportToDelete)}
				onDeny={() => {
					setOpenConfirm(false);
					setReportToDelete(null);
				}}
				confirmText="Elimina"
			/>
			<ConfirmModal
				open={openFailDelete}
				title="Eliminazione Report Fallita"
				text="Impossibile eliminare il Report selezionato. Si potrebbero non avere le autorizzazioni necessarie. Se il problema persiste si prega di contattare l'assistenza."
				onConfirm={() => setOpenFailDelete(false)}
				confirmText="OK"
			/>
			<ConfirmModal
				open={openFailCreate}
				title="Creazione Report Fallita"
				text="C'è stato un errore nella generazione di questo Report. Se il problema persiste si prega di contattare l'assistenza."
				onConfirm={() => setOpenFailCreate(false)}
				confirmText="OK"
			/>
			<DataGrid
				columns={visibleColumns}
				rows={tableData}
				query={query?.query?.split(" ") ?? []}
				onSortColumnsChange={(col) => setOrder(col.map((c) => c.key))}
				rowKeyGetter={(row) => row.idReport}
				totalResults={reports.totalResults}
				setPage={(page) => setQuery({ ...query, page })}
				currPage={query.page}
				sortData={sort}
				onSortDataChange={setSort}
			/>
			<br />
			<br />
			<br />
		</div>
	);
}
