import { TextField, Autocomplete } from '@mui/material';
import { useFetch } from '../../../Hooks/useFetch';

export default function PosSelect({ disabled, onChange, value, required, posList, style }) {
	const curr = posList?.find((pos) => `${pos.identificativoPos}///${pos.vendor}` === value);
	return posList && posList.length > 0 && (
		<Autocomplete
			disablePortal
			autoHighlight
			disabled={disabled}
			id="combo-box-demo"
			className="w-100 mt-2"
			style={style}
			options={posList ? posList
				.map((pos) => ({
					label: pos.nome,
					idPos: `${pos.identificativoPos}///${pos.vendor}`
				})) : []}
			isOptionEqualToValue={(option, v) => option.idPos === v.idPos}
			getOptionLabel={(option) => option?.label}
			onChange={(event, item) => onChange(item?.idPos)}
			value={curr ? {
				label: curr.nome,
				idPos: `${curr.identificativoPos}///${curr.vendor}`
			} : null}
			renderOption={(props, option) => (
				<li {...props} key={option.idPos}>{option.label}</li>
			)}
			renderInput={(fieldParams) => (
				<TextField {...fieldParams} required={required} label="Dispositivo POS" variant="standard" />
			)}
			ListboxProps={{ style: { maxHeight: '250px' } }}
		/>
	);
}
