/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useController, useFieldArray, Controller, useFormContext } from "react-hook-form";
import BasePlugin from "../BasePlugin";


export default class SovratassePlugin extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.sovratasse.SovratasseConfiguration",
			nome: "Gestione sovratasse",
			codicePlugin: "SOVRATASSE",
			tipo: null,
			scadenzeCustom: [],
			ordine: this.configuration.index,
			descrizione: "Gestisce la configurazione delle sovratasse."
		};
	}

	/**
	 * Componente chiamato durante il rendering del form di
	 * configurazione del tributo (backoffice).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @param baseFormPath Nome base degli input da inserire nel form.
	 * @param setValue Setta manualmente un valore del form.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		const { field: tipoField } = useController({ name: `${path}.tipo` });
		const { fields, append, remove } = useFieldArray({ name: `${path}.scadenzeCustom` });

		return (
			<div>
				<FormControl variant="standard" className="w-100 mt-3">
					<InputLabel id="tipoId">Tipo avviso ridotta (default)</InputLabel>
					<Select labelId="tipoId" value={tipoField.value} required onChange={tipoField.onChange}>
						<MenuItem value="RavvedimentoOperoso">Ravvedimento operoso</MenuItem>
						<MenuItem value="DopoScadenza">Scadenze personalizzate</MenuItem>
					</Select>
				</FormControl>

				{tipoField.value === "DopoScadenza" && (
					<div>
						<Button className="mt-3" onClick={() => append({ scadenza: 1, percentuale: 0.01 })}>Aggiungi scadenza</Button>
						{
							fields.map((element, idx) => (
								<div className="mt-3 d-flex align-items-end flex-row">
									<Controller
										name={`${path}.scadenzeCustom.${idx}.scadenza`}
										render={({ field }) => <TextField type="number" value={field.value} onChange={(e) => field.onChange(parseInt(e.target.value, 10))} variant="standard" label="Giorni dopo la scadenza" className="w-25 mt-3 mr-3" />}
									/>
									<Controller
										name={`${path}.scadenzeCustom.${idx}.percentuale`}
										render={({ field }) => <TextField type="number" value={field.value * 100.0} onChange={(e) => field.onChange(parseFloat(e.target.value) / 100.0)} variant="standard" label="Percentuale della sovratassa" className="w-25 mt-3 mr-3" />}
									/>
									<Button className="mr-3" onClick={() => remove(idx)}>Cancella scadenza</Button>
								</div>
							))
						}
					</div>
				)}


			</div>
		);
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentFormEditor({ config, basePath }) {
		const { setValue, watch } = useFormContext();

		useEffect(() => {
			setValue(`${basePath}.@class`, "it.ras.pagopa.shared.plugins.pagamenti.sovratasse.SovratasseData");
			setValue(`${basePath}.codicePlugin`, "SOVRATASSE");
		}, []);
	}
}
