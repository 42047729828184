/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

export default function HierarchySelect({
	trees, onChange, selected, keyProp, labelProp,
	error, description, name, title, required, filterEnableOptions
}) {
	const [isOpen, setIsOpen] = useState(false);
	const button = useRef(null);

	function drawNode(node, i) {
		const linkEnabled = filterEnableOptions(node);
		if (node[keyProp] === selected?.[keyProp]) {
			return (
				<li key={`select-${name}-${node[keyProp]}`} className="selected active">
					<a onClick={() => { onChange(node); }} role="option" className="dropdown-item active selected" tabIndex={i} aria-selected="true" style={linkEnabled ? { pointerEvents: "none", color: "grey" } : {}}>
						<span className="text">{node[labelProp]}</span>
					</a>
					<ul className="dropdown-menu inner show" role="presentation" style={{ background: "transparent", borderLeft: "2px solid #005c99", padding: 0, margin: 0, marginLeft: "25px" }}>
						{node.figli.map((cNode, ii) => drawNode(cNode, ii * i))}
					</ul>
				</li>
			);
		}

		return (
			<li key={`select-${name}-${node[keyProp]}`}>
				<a onClick={() => { onChange(node); }} role="option" tabIndex={i} className="dropdown-item" style={linkEnabled ? { pointerEvents: "none", background: "transparent", color: "grey" } : { background: "transparent" }}>
					<span className="text">{node[labelProp]}</span>
				</a>
				<ul className="dropdown-menu inner show" role="presentation" style={{ background: "transparent", borderLeft: "2px solid #005c99", padding: 0, margin: 0, marginLeft: "25px" }}>
					{node.figli.map((cNode, ii) => drawNode(cNode, ii * i))}
				</ul>
			</li>
		);
	}

	useEffect(() => {
		const clickHandler = (ev) => {
			if (button.current && button.current.contains(ev.target)) setIsOpen(!isOpen);
			else setIsOpen(false);
		};

		window.addEventListener('click', clickHandler);
		return () => window.removeEventListener('click', clickHandler);
	}, []);

	return (
		<div className="dropdown bootstrap-select form-select">
			<h5>
				{title}
				{required && ' *'}
			</h5>
			<button
				ref={button}
				onClick={() => setIsOpen(!isOpen)}
				type="button"
				tabIndex="-1"
				id={`select-${name}-id`}
				className="btn dropdown-toggle btn-light"
				data-toggle="dropdown"
				aria-owns="bs-select-1"
				aria-haspopup="listbox"
				aria-expanded="false"
				data-id="paginazione-per-pagina"
				title={selected?.[labelProp]}
			>
				<span className="filter-option">
					<span className="filter-option-inner">
						<span className="filter-option-inner-inner">{selected?.[labelProp]}</span>
					</span>
				</span>
			</button>
			<div className={classNames({ 'dropdown-menu': true, show: isOpen })}>
				<div className="inner show" role="listbox" style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden" }} id="bs-select-1" tabIndex="-1" aria-activedescendant="bs-select-1-1">
					<ul className="dropdown-menu inner show" role="presentation">
						{trees.map((cNode, i) => drawNode(cNode, i))}
					</ul>
				</div>
			</div>
			<p style={error && { color: "red" }}><i>{error ?? description}</i></p>
		</div>
	);
}
