/* eslint-disable react/no-array-index-key */
import { cloneElement, useEffect, useState, Fragment } from 'react';
import { Checkbox, IconButton } from '@mui/material';
import DataTableColumn from './DataTableColumn';


export default function DataTable({
	children, data, idField, childrenField, className,
	// - Pagination
	page, pageSize, onPageChange, totalCount, onClick
}) {
	// - Gets the field from a path
	const getValue = (row, fn) => fn?.split('.').reduce((o, i) => o[i], row);

	const linearizeData = (rows) => rows.reduce((acc, d) => [
		...acc, d, ...linearizeData(getValue(d, childrenField))
	], []);
	const linearizedData = data && (!childrenField ? data : linearizeData(data));

	// - Initializes an array of ID - Boolean false
	const reducer = (init) => (map, obj) => ({ ...map, [getValue(obj, idField)]: init });
	const openInit = (init) => linearizedData.reduce(reducer(init), {});
	const [open, setOpen] = useState(openInit(false));

	// - Colonna che mostra il pulsante per espandere una parte dell'albero
	// =========================================================================
	const checkOpen = (id) => (id ? open[id] : Object.values(linearizedData
		.filter((x) => getValue(x, childrenField).length > 0))
		.every((elem) => open[getValue(elem, idField)]));
	const newOpenState = (id) => (id ? { ...open, [id]: !open[id] } : openInit(!checkOpen(null)));
	const toggleOpen = (id) => () => setOpen(newOpenState(id));

	const buttonIconExp = (id) => (
		<i className={`fas fa-fw fa-${checkOpen(id) ? 'angle-down' : 'angle-right'}`} />
	);

	const buttonExpand = (id) => (
		<IconButton onClick={toggleOpen(id)}>{buttonIconExp(id)}</IconButton>
	);

	const renderExpandHeader = () => buttonExpand(null);
	const renderExpandContent = (row) => getValue(row, childrenField)
		&& getValue(row, childrenField).length > 0
		&& buttonExpand(getValue(row, idField));

	const childrenColumn = () => (
		<DataTableColumn min renderHeader={renderExpandHeader} renderContent={renderExpandContent} />
	);
	// =========================================================================

	// - Preparo la lista di colonne da mostrare nella tabella
	const columns = (childrenField ? [childrenColumn(), ...children] : children)
		.filter((x) => x).flat();

	// - Funzione di rendering dei dati
	const renderCells = (row, i, indent = 0) => (
		<Fragment key={i}>
			<tr key={i} onClick={() => onClick?.(row)} style={onClick ? { cursor: "pointer" } : {}}>
				{
					columns.map((col, key) => cloneElement(col, { row, indent, key, idField, i }))
				}
			</tr>
			{
				childrenField && checkOpen(getValue(row, idField))
				&& getValue(row, childrenField).length > 0
				&& getValue(row, childrenField).map((r, ii) => renderCells(r, `${i}${ii}`, indent + 1))
			}
		</Fragment>
	);

	// - Paginazione
	const numberOfPages = Math.ceil(totalCount / pageSize);
	const pages = [];
	for (let i = Math.max(page - 4, 0); i < Math.min(page + 4, numberOfPages); i += 1) pages.push(i);

	return (
		<div className={className}>
			<table>
				<thead>
					<tr>
						{columns.map((col, i) => cloneElement(col, { idField, key: `H-${i}` }))}
					</tr>
				</thead>
				<tbody>
					{data && data.map((row, i) => renderCells(row, `${i}`))}
				</tbody>
			</table>
			{
				numberOfPages > 1 && (
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<IconButton aria-label="precedente" onClick={() => onPageChange(Math.max(page - 1, 0))}>
							<i className="fas fa-fw fa-chevron-left" />
						</IconButton>
						{pages[0] !== 0 ? <i className="fas fa-fw fa-ellipsis-h" style={{ opacity: 0.6 }} /> : ""}
						{
							pages.map((p) => (
								<IconButton key={p} aria-label="precedente" onClick={() => onPageChange(p)}>
									<div style={p === page ? { fontWeight: 'bold' } : {}}>{p + 1}</div>
								</IconButton>
							))
						}
						{pages[pages.length - 1] !== numberOfPages - 1 ? <i className="fas fa-fw fa-ellipsis-h" style={{ opacity: 0.6 }} /> : ""}
						<IconButton aria-label="successivo" onClick={() => onPageChange(Math.min(page + 1, numberOfPages - 1))}>
							<i className="fas fa-fw fa-chevron-right" />
						</IconButton>
					</div>
				)
			}
		</div>
	);
}
