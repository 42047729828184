/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import BasePlugin from "../BasePlugin";
import DownloadPaymentNotice from "../../Portal/Components/CarrelloPagamento/DownloadPaymentNotice";

function giornoPasquetta(anno) {
	const g = anno % 19;
	const c = anno / 100;
	let h = (c - c / 4 - (8 * c + 13) / 25 + 19 * g + 15) % 30;
	if (h < 0) h -= 30;
	const i = h - (h / 28) * (1 - (29 / (h + 1))) * ((21 - g) / 11);
	const j = (anno + anno / 4 + i + 2 - c + c / 4) % 7;
	const greg = i - j;
	const mese = 3 + (greg + 40) / 44;
	const giorno = greg + 28 - 31 * (mese / 4);
	return moment([anno, mese, giorno]);
}

function prossimoGiornoNonFestivo(giornoBase) {
	const giorniFestivi = [[1, 1], [6, 1], [25, 4], [1, 5], [2, 6], [15, 8], [1, 11], [8, 12], [25, 12], [26, 12]];
	let currentDay = giornoBase.clone();

	const isDayValid = (day) => {
		// - Domenica
		if (day.weekday() === 0) return false;
		// - Giorno festivo
		if (giorniFestivi.some(([d, m]) => day.date() === d && day.month() === m)) return false;
		// - Pasquetta
		const pasquetta = giornoPasquetta(day.year());
		if (pasquetta.day() === day.day() && pasquetta.month() === day.month()) return false;

		return true;
	};

	while (!isDayValid(currentDay)) { currentDay = currentDay.add(1, "day"); }

	return currentDay.clone();
}

export default class SanzioniPlugin extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniConfiguration",
			nome: "Gestione sanzioni",
			codicePlugin: "SANZIONI",
			defaultTipoRidotta: null,
			defaultTipoScontata: null,
			importoRidotto: false,
			ordine: this.configuration.index,
			descrizione: "Gestisce la configurazione delle sanzioni."
		};
	}


	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentSubmit({ formValues, pluginData }) {
		return {
			formValues,
			pluginData: [...pluginData.filter((e) => e.codicePlugin !== "SANZIONI"), pluginData?.find((e) => e.codicePlugin === "SANZIONI") ?? {
				codicePlugin: "SANZIONI",
				"@class": "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData"
			}]
		};
	}

	/**
	 * Trasforma una mappa di valori <nomeCampo, valore> in un debito o modifica il debito già
	 * creato precedentemente da altri plugin.
	 * @param fields La mappa di valori ottenuta dal form semplificato.
	 * @param baseDebit Il debito da modificare.
	 * @returns {*} Il debito modificato.
	 */
	backofficePaymentEditorSubmit(fields, baseDebit) {
		let altCurr = null;

		if (this.configuration.importoRidotto || fields.tipoAvvisoScontata) {
			// - Prendo tutte le pendenze per una specifica rata
			const pendenzeRata = baseDebit.pendenze.filter((p) => p.numeroRata === 0);
			// - Calcolo il numero di rata alternativa da applicare
			altCurr = Math.max(pendenzeRata.map((p) => p.altRata)) + 1;

			// - Creo una nuova entità dettagli per evitare modifiche di stato
			const newDettagli = JSON.parse(JSON.stringify(pendenzeRata[0].dettagli));

			// - Applica lo sconto
			const voceBase = newDettagli.find((d) => d.codiceDettaglio === "capitale");
			voceBase.importo -= Math.trunc(((voceBase.importo / 100.0) * 30.0) * 100.0) / 100.0;

			baseDebit.pendenze.push({
				...pendenzeRata[0],
				numeroRata: 0,
				altRata: altCurr,
				"@class": "it.ras.pagopa.shared.dto.pendenze.PendenzaNewDto",
				dettagli: newDettagli
			});
		}

		const plugin = baseDebit.pluginData.find((sett) => sett.codicePlugin === this.configuration.codicePlugin);
		plugin.altScontata = altCurr;
		plugin.altRidotta = 0;
		plugin.tipoScontata = fields.tipoAvvisoScontata ?? this.configuration.defaultTipoScontata ?? "Entro5Giorni";
		plugin.tipoRidotta = fields.tipoAvvisoRidotta ?? this.configuration.defaultTipoRidotta ?? "Entro60Giorni";

		return baseDebit;
	}

	/**
	 * Componente chiamato durante il rendering del form da
	 * compilare nei pagamenti spontanei (Portale).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @returns {JSX.Element}
	 */
	paymentSummary({ debit, config, data, path, viewOnly }) {
		if (debit?.pendenze.some((p) => p.numeroRata > 0)) return <></>;
		const { register, setValue, resetField } = useFormContext();
		const { field: ricezioneNotifica } = useController({ name: `${path}.ricezioneNotifica` });

		const style = { maxWidth: "500px", borderRadius: 1, border: "1px solid #FFFFFF !important" };

		const durata = (d) => {
			switch (d) {
				case "Entro5Giorni": return 5;
				case "Entro30Giorni": return 30;
				case "Entro60Giorni": return 60;
				default: return 0;
			}
		};

		const gScontata = data?.importoRidotto ? durata(data?.tipoScontata) : null;
		const gRidotta = durata(data?.tipoRidotta);

		const ricezioneNotificaMoment = ricezioneNotifica.value ? moment(ricezioneNotifica.value, "DD/MM/YYYY") : moment();
		const limiteScontata = ricezioneNotificaMoment.clone().add(gScontata + 1, "day");
		const limiteScontataFest = prossimoGiornoNonFestivo(limiteScontata);
		const limiteRidotta = ricezioneNotificaMoment.clone().add(gRidotta + 1, "day");
		const limiteRidottaFest = prossimoGiornoNonFestivo(limiteRidotta);

		const currScadenza = moment().isBetween(limiteScontataFest.endOf('day'), limiteRidottaFest.endOf('day')) ? limiteScontataFest : limiteRidottaFest;

		useEffect(() => setValue(`${path}.@class`, "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData"), []);

		if (viewOnly) return <></>;

		return (
			<div>
				<input type="hidden" value="it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData" {...register(`${path}.@class`)} />
				<div className="mt-5 d-flex flex-row align-items-center">
					<div className="mr-5">
						<h3>Informazioni sulla ricevuta della sanzione</h3>
						Quando hai ricevuto la notifica di pagamento?
					</div>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<DesktopDatePicker
							onChange={(v) => ricezioneNotifica.onChange(v.format("DD-MM-YYYY"))}
							value={ricezioneNotifica.value ? moment(ricezioneNotifica.value, "DD/MM/YYYY") : null}
							inputFormat="DD/MM/YYYY"
							placeholder="Data ricezione notifica"
							renderInput={(props) => <TextField {...props} required style={style} />}
						/>
					</LocalizationProvider>
				</div>
				{ricezioneNotifica.value && (
					<div className="mt-3 form-check">
						<input id="patrono-check" type="checkbox" {...register(`${path}.patrono`)} />
						<label htmlFor="patrono-check">
							Il giorno <b>{currScadenza.format("DD-MM-YYYY")}</b> coincide con il Santo Patrono del proprio Comune di residenza
						</label>
					</div>
				)}
			</div>
		);
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	portalPaymentSubmit({ formValues, pluginData }) {
		return {
			formValues,
			pluginData: [
				...pluginData,
				{
					altRidotta: 0,
					tipoRidotta: formValues.tipoAvvisoRidotta ?? this.configuration.defaultTipoRidotta ?? "Entro60Giorni",
					altScontata: 1,
					tipoScontata: formValues.tipoAvvisoScontata ?? this.configuration.defaultTipoScontata,
					codicePlugin: "SANZIONI",
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData"
				}
			]
		};
	}


	/**
	 * Componente chiamato durante il rendering del form da
	 * compilare nei pagamenti spontanei (Portale).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @returns {JSX.Element}
	 */
	paymentSummaryButtons({ debit, config, data, path, viewOnly, payeeView }) {
		if (debit?.pendenze.some((p) => p.numeroRata > 0)) return <></>;
		const { setValue, watch } = useFormContext();
		const ricezioneNotifica = useWatch({ name: `${path}.ricezioneNotifica` });
		const selectedRata = useWatch({ name: `selectedRata` });
		const pluginData = useWatch({ name: `pendenze.0.pluginData` });
		const patrono = useWatch({ name: `${path}.patrono` });

		useEffect(() => {
			setValue(`codicePlugin`, "SANZIONI");
			setValue(`@class`, "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData");
		}, []);

		const durata = (d) => {
			switch (d) {
				case "Entro5Giorni": return 5;
				case "Entro30Giorni": return 30;
				case "Entro60Giorni": return 60;
				default: return 0;
			}
		};

		const tipoScontata = data?.tipoScontata ?? config.defaultTipoScontata ?? null;
		const tipoRidotta = data?.tipoRidotta ?? config.defaultTipoRidotta ?? null;

		const gScontata = config?.importoRidotto ? durata(tipoScontata) : null;
		const gRidotta = durata(tipoRidotta);

		const ricezioneNotificaMoment = ricezioneNotifica ? moment(ricezioneNotifica, "DD/MM/YYYY") : moment();
		const limiteScontata = ricezioneNotificaMoment.clone().add(gScontata + 1 + (patrono ? 1 : 0), "day");
		const limiteScontataFest = prossimoGiornoNonFestivo(limiteScontata);

		const currScontata = tipoScontata && moment().isBefore(limiteScontataFest.endOf('day'));

		const scontata = debit.pendenze.find((p) => p.altRata === data?.altScontata && p.numeroRata === (selectedRata?.numeroRata ?? 0));
		const ridotta = debit.pendenze.find((p) => p.altRata === data?.altRidotta && p.numeroRata === (selectedRata?.numeroRata ?? 0));

		useEffect(() => {
			setValue("selectedRata", currScontata ? scontata : ridotta);
			setValue(`${path}.ricezioneNotifica`, ricezioneNotifica);
			setValue(`${path}.patrono`, patrono);
		}, [currScontata, patrono, ricezioneNotifica]);

		return !viewOnly ? (
			<>
				{config?.importoRidotto && scontata && currScontata && <DownloadPaymentNotice color={currScontata ? undefined : "outline-danger"} disabled={payeeView} iuv={scontata.iuv} codiceEnte={debit.ente.codiceEnte} pluginData={pluginData} text={`Avviso entro ${gScontata} giorni dalla data di notifica`} />}
				{config?.importoRidotto && scontata && !currScontata && (
					<div style={{ position: "relvative", marginTop: "-5px" }}>
						<DownloadPaymentNotice color={currScontata ? undefined : "outline-danger"} disabled={payeeView} iuv={scontata.iuv} codiceEnte={debit.ente.codiceEnte} pluginData={pluginData} text={`Avviso entro ${gScontata} giorni dalla data di notifica`} />
						<div style={{ position: "absolute", fontSize: "12px" }}>Attenzione: sono trascorsi i termini di pagamento con importo scontato</div>
					</div>
				)}
				{ridotta && !currScontata && <DownloadPaymentNotice color={!currScontata ? undefined : "outline-danger"} disabled={payeeView} iuv={ridotta.iuv} codiceEnte={debit.ente.codiceEnte} pluginData={pluginData} text={`Avviso entro ${gRidotta} giorni dalla data di notifica`} />}
				{ridotta && currScontata && (
					<div style={{ position: "relvative", marginTop: "-5px" }}>
						<DownloadPaymentNotice color={!currScontata ? undefined : "outline-danger"} disabled={payeeView} iuv={ridotta.iuv} codiceEnte={debit.ente.codiceEnte} pluginData={pluginData} text={`Avviso entro ${gRidotta} giorni dalla data di notifica`} />
						<div style={{ position: "absolute", fontSize: "12px" }}>Attenzione: sono trascorsi i termini di pagamento con importo ridotto</div>
					</div>
				)}
			</>
		) : <></>;
	}

	/**
	 * Componente chiamato durante il rendering del form di
	 * configurazione del tributo (backoffice).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @param baseFormPath Nome base degli input da inserire nel form.
	 * @param setValue Setta manualmente un valore del form.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		const { field: defaultTipoScontata } = useController({ name: `${path}.defaultTipoScontata` });
		const { field: defaultTipoRidotta } = useController({ name: `${path}.defaultTipoRidotta` });
		const { field: importoRidotto } = useController({ name: `${path}.importoRidotto` });

		return (
			<div>
				<div>
					<Switch onChange={(v) => importoRidotto.onChange(v.target.checked)} checked={importoRidotto.value} />
					{importoRidotto.value ? <b>Sanzione scontata abilitata.</b> : <>Abilita sanzione scontata.</>}
				</div>

				{importoRidotto.value && (
					<FormControl variant="standard" className="w-100 mt-3">
						<InputLabel id="avvisoScontataId">Tipo avviso scontata (default)</InputLabel>
						<Select labelId="avvisoScontataId" value={defaultTipoScontata.value} required onChange={defaultTipoScontata.onChange}>
							<MenuItem value="Entro5Giorni">Entro 5 giorni</MenuItem>
							<MenuItem value="Entro30Giorni">Entro 30 giorni</MenuItem>
							<MenuItem value="Entro60Giorni">Entro 60 giorni</MenuItem>
						</Select>
					</FormControl>
				)}

				<FormControl variant="standard" className="w-100 mt-3">
					<InputLabel id="avvisoScontataId">Tipo avviso ridotta (default)</InputLabel>
					<Select labelId="avvisoScontataId" value={defaultTipoRidotta.value} required onChange={defaultTipoRidotta.onChange}>
						<MenuItem value="Entro5Giorni">Entro 5 giorni</MenuItem>
						<MenuItem value="Entro30Giorni">Entro 30 giorni</MenuItem>
						<MenuItem value="Entro60Giorni">Entro 60 giorni</MenuItem>
					</Select>
				</FormControl>
			</div>
		);
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentFormEditor({ config, basePath, payeeView }) {
		const { field: altScontata } = useController({ name: `${basePath}.altScontata` });
		const { field: tipoScontata } = useController({ name: `${basePath}.tipoScontata` });
		const { field: altRidotta } = useController({ name: `${basePath}.altRidotta` });
		const { field: tipoRidotta } = useController({ name: `${basePath}.tipoRidotta` });
		useController({ name: `${basePath}.@class`, defaultValue: "it.ras.pagopa.shared.plugins.pagamenti.sanzioni.SanzioniData" });

		return (
			<div>
				{config.importoRidotto && (
					<TextField value={altScontata.value ?? 0} onChange={altScontata.onChange} variant="standard" label="Rata alt scontata" className="w-25 mt-3 mr-3" type="number" />
				)}

				{config.importoRidotto && (
					<FormControl variant="standard" className="w-50 mt-3">
						<InputLabel id="avvisoScontataId">Tipo avviso scontata</InputLabel>
						<Select labelId="avvisoScontataId" value={tipoScontata.value ?? "Entro5Giorni"} required onChange={tipoScontata.onChange}>
							<MenuItem value="Entro5Giorni">Entro 5 giorni</MenuItem>
							<MenuItem value="Entro30Giorni">Entro 30 giorni</MenuItem>
							<MenuItem value="Entro60Giorni">Entro 60 giorni</MenuItem>
						</Select>
					</FormControl>
				)}

				<TextField value={altRidotta.value ?? 0} onChange={altRidotta.onChange} variant="standard" label="Rata alt ridotta" className="w-25 mt-3 mr-3" type="number" />

				<FormControl variant="standard" className="w-50 mt-3">
					<InputLabel id="avvisoScontataId">Tipo avviso ridotta</InputLabel>
					<Select labelId="avvisoScontataId" value={tipoRidotta.value ?? "Entro5Giorni"} required onChange={tipoRidotta.onChange}>
						<MenuItem value="Entro5Giorni">Entro 5 giorni</MenuItem>
						<MenuItem value="Entro30Giorni">Entro 30 giorni</MenuItem>
						<MenuItem value="Entro60Giorni">Entro 60 giorni</MenuItem>
					</Select>
				</FormControl>
			</div>
		);
	}
}
