/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-body-style */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
import { Button } from 'design-react-kit';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

import Footer from './Layout/Footer';
import Header from './Layout/Header';
import { resetArea, selectArea, setPassiveToken } from "../Store/Authentication";
import useToken from "../Hooks/useToken";

import configuration from '../configuration';
import InputConDescrizione from "../Portal/Components/Common/InputConDescrizione";

function AddImpresa({ onNewImpresa, noImprese }) {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(null);
	const [iva, setIva] = useState("");
	const [searching, setSearching] = useState(false);

	const jwt = useToken();

	function apiCall() {
		const data = new FormData();
		data.append("iva", iva);
		setSearching(true);
		setError(null);

		fetch(`${configuration.serverAddress}/auth/registraImpresa`, {
			method: 'POST',
			headers: new Headers({
				Authorization: `Bearer ${jwt}`
			}),
			body: iva
		}).then((res) => {
			if (res.status === 403) throw new Error("Sul Registro Imprese non risulti rappresentante legale dell'impresa inserita.");
			if (res.status !== 200) throw new Error("Errore di comunicazione con il registro imprese.");
			return {};
		}).then((response) => {
			setOpen(false);
			setSearching(false);
			onNewImpresa?.();
		}).catch((e) => {
			setSearching(false);
			setError(e.message);
		});
	}

	if (!open) {
		return (
			<div style={{ height: "42px", textAlign: "center" }}>
				<button type="button" className="btn btn-primary" onClick={() => setOpen(true)}>
					{
						noImprese
							? "Se vuoi gestire debiti e pagamenti per un'impresa, clicca qui"
							: "Se la tua impresa non è presente nell'elenco, clicca qui"
					}
				</button>
			</div>
		);
	}
	return (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
			<form style={{ height: "42px", textAlign: "center", display: "flex", flexDirection: "row", alignItems: "flex-end", columnGap: "20px" }}>
				<InputConDescrizione tipo="text" placeholder="Partita IVA o codice fiscale dell'impresa" nome="intestatario" onChange={(e) => setIva(e.target.value)} value={iva} style={{ width: "350px" }} />
				<button type="button" className="btn btn-primary" onClick={() => apiCall()} disabled={searching}>
					CERCA
				</button>
			</form>
			{
				error && (
					<div className="alert alert-danger mt-3" role="alert">
						<b>Errore: </b>
						{error}
					</div>
				)
			}
		</div>
	);
}

function Role({ ignoreSingleRole }) {
	// - Prende il token JWT dall'URL generato dall'autenticazione IDM
	const params = useParams();
	const dispatch = useDispatch();
	// - Stato della pagina di autenticazione
	const [refresh, setRefresh] = useState(0);
	const [errors, setError] = useState(null);
	const [userAccounts, setUserAccounts] = useState([]);
	const [intestatario, setIntestatario] = useState({ nome: ' ', cognome: ' ', codiceFiscale: ' ', ultimoAccesso: ' ', partitaIva: ' ', ragioneSociale: ' ' });
	const [utente, setUtente] = useState({ nome: ' ', cognome: ' ', codiceFiscale: ' ' });
	const [isLoading, setIsLoading] = useState(true);
	const [firstLoad, setFirstLoad] = useState(true);
	// - Gestione della navigazione
	const history = useHistory();
	const jwt = useToken();
	const user = useSelector((state) => state.authentication.user);

	const isTablet = useMediaQuery("(max-width: 768px)");
	const isMobileLarge = useMediaQuery("(max-width: 425px)");

	if (params.jwt) {
		console.log("Acquiring JWT...");
		dispatch(setPassiveToken(params.jwt));
	}

	if (params.jwt && jwt) {
		console.log("Redirect...");
		history.replace('/autenticazione/ruolo');
	}

	if (!params.jwt && !jwt) {
		console.log("No token.");
		history.replace("/");
	}

	useEffect(() => {
		dispatch(resetArea());
	}, []);

	const getPersonaFisica = (accounts) => {
		if (accounts.length === 1) {
			return accounts[0];
		}
		return accounts.filter((account) => account.codiceFiscale != null)[0];
	};

	useEffect(() => {
		setError(null);
		setIsLoading(true);
		// - Prendo le informazioni del cittadino per verificare gli accessi a disposizione
		fetch(`${configuration.serverAddress}/user/profiles`, {
			method: 'GET',
			headers: new Headers({ Authorization: `Bearer ${jwt}`, ...configuration.defaultHeaders })
		}).then((res) => {
			if (res.status !== 200) {
				throw new Error('Errore nella ricezione delle informazioni utente.');
			}
			return res.json();
		}).then((response) => {
			setError(null);
			setUserAccounts(response);
			setIntestatario(getPersonaFisica(response));
			setUtente(response[0].utente);
			setIsLoading(false);
		}).catch(() => {
			setError('Utente non autenticato. Tornare alla homepage.');
		});
	}, [jwt, refresh]);

	useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			return;
		}
		switch (user?.tipo) {
			case "Cittadino":
			case "Impresa":
				history.push('/area-personale');
				break;
			case "Backoffice":
			case "Admin":
			case "Operatore Ente":
			case "Amministratore Ente":
			case "Osservatore ente Ente":
			case "Interessi Legali":
				history.push('/backoffice');
				break;
			default:
				break;
		}
	}, [user?.tipo, user?.id]);


	const goToPortal = (id) => () => dispatch(selectArea({ jwt, id }));
	const goToBackoffice = (id) => () => dispatch(selectArea({ jwt, id }));

	let ultimoAccesso = ' ';
	if (userAccounts.length > 0) {
		ultimoAccesso = userAccounts[0].ultimoAccesso ? new Date(userAccounts[0].ultimoAccesso).toLocaleString() : 'Primo accesso';
	}

	useEffect(() => {
		if (userAccounts.length === 1 && !ignoreSingleRole) {
			goToPortal(userAccounts[0].id)();
		}
	}, [userAccounts]);

	const groupBy = (items, key) => items.reduce(
		(result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item] }), {}
	);

	const tipiDisponibili = groupBy(userAccounts, 'tipo');

	if (userAccounts.length < 2 && !ignoreSingleRole) return <></>;

	return (
		<>
			<Header />
			<div className={isMobileLarge ? "bg-lightblue text-sans-serif" : "bg-lightblue p-5 text-sans-serif"} style={{ height: '100%' }}>

				{
					errors ? <div className="container rounded bg-danger p-3 mb-3 text-white text-center"><b>{errors}</b></div> : ''
				}

				<div className="container pl-0 pr-0 bg-white text-center rounded">
					<h1 className="display-1 pt-4 pb-4">{`${utente.nome} ${utente.cognome}`}</h1>
					<h4 className="bg-lightgrey pt-2 pb-2 rounded-bottom">Selezionare il ruolo desiderato</h4>
				</div>

				<div className="container bg-white p-0 pb-3 pt-3 mt-4 rounded">
					<div className="mt-3 ml-5 mr-5 mb-5 font-weight-normal">
						<p>
							Le seguenti informazioni sono associate al tuo <b>profilo SPID.</b>
						</p>
						<div style={{ display: "flex", flexDirection: "row", gap: "10px", flexWrap: "wrap", alignItems: "flex-end" }}>
							<div style={{ flexBasis: "256px", flexGrow: 1 }}>
								<InputConDescrizione tipo="text" titolo="Denominazione" nome="intestatario" value={`${utente?.nome} ${utente?.cognome}`} readonly style={{ flexBasis: "256px", flexGrow: 1 }} />
							</div>
							<div style={{ flexBasis: "256px", flexGrow: 1 }}>
								<InputConDescrizione tipo="text" titolo="Codice Fiscale" nome="codiceFiscale" value={utente?.codiceFiscale} readonly />
							</div>
							<div style={{ flexBasis: "256px", flexGrow: 1 }}>
								<InputConDescrizione tipo="text" titolo="Ultimo Accesso" nome="ultimoAccesso" value={ultimoAccesso} readonly style={{ flexBasis: "256px", flexGrow: 1 }} />
							</div>
						</div>
					</div>
					<div className="bg-lightgrey pl-5 pr-5 pt-3 pb-3 text-center">
						<b>Seleziona il ruolo con il quale intendi operare</b>
						<br />
						(puoi scegliere un solo ruolo per volta)
					</div>
					<div className="d-flex flex-wrap align-content-center m-5" style={{ rowGap: "34px", columnGap: "34px" }}>
						{
							'Cittadino' in tipiDisponibili && ((cittadino) => {
								return (
									<div className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
										<div className="ml-2 text-uppercase">
											Cittadino
											<br />
											<b>{((ints) => `${ints.nome} ${ints.cognome}`)(tipiDisponibili.Cittadino[0].intestatario)}</b>
										</div>
										{/* eslint-disable-next-line */}
										<div align="right" className="mt-auto">
											<button type="button" className="btn btn-primary" onClick={goToPortal(cittadino[0].id)}>SELEZIONA</button>
										</div>
									</div>
								);
							})(tipiDisponibili.Cittadino)
						}
						{
							('Impresa' in tipiDisponibili || true) && tipiDisponibili.Impresa?.map((x) => (
								<div className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Impresa
										<br />
										<b>{((ints) => `${ints.ragioneSociale} (${ints.partitaIva})`)(x.intestatario)}</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToPortal(x.id)}>SELEZIONA</button>
									</div>
								</div>
							))
						}
					</div>
					<div className="mt-2 mb-3 p-2 d-flex justify-content-center w-100">
						<AddImpresa onNewImpresa={() => setRefresh((v) => v + 1)} noImprese={!('Impresa' in tipiDisponibili)} />
					</div>
					{
						('Impresa' in tipiDisponibili) && (
							<div className="mt-2 p-2 pl-5 align-items-center">
								<small>
									Le imprese elencate sono ricavate da un collegamento con il registro imprese
									nazionale. Nel caso in cui l’elenco fosse incompleto
									o errato si prega di contattare
									l’assistenza pagoPA ai seguenti riferimenti: <br />
									telefono <b>070 606 4444</b> <br />
									email <b>pagopa@regione.sardegna.it</b> <br />
								</small>
							</div>
						)
					}
					{
						'Operatore Ente' in tipiDisponibili && (
							<div className="bg-lightgrey pl-5 pr-5 pt-3 pb-3 text-center">
								<b>Ruoli per l&apos;amministrazione di enti in backoffice</b>
							</div>
						)
					}
					<div className="d-flex flex-wrap align-content-center m-5" style={{ rowGap: "34px", columnGap: "34px" }}>
						{
							'Osservatore ente Ente' in tipiDisponibili && tipiDisponibili['Osservatore ente Ente'].map((x) => (
								<div key={x.ente.intestatario.codiceFiscale} className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Osservatore ente
										<br />
										<b>{x.ente.intestatario.denominazione}</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToBackoffice(x.id)}>SELEZIONA</button>
									</div>
								</div>
							))
						}
						{
							'Operatore Ente' in tipiDisponibili && tipiDisponibili['Operatore Ente'].map((x) => (
								<div key={x.ente.intestatario.codiceFiscale} className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Operatore ente
										<br />
										<b>{x.ente.intestatario.denominazione}</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToBackoffice(x.id)}>SELEZIONA</button>
									</div>
								</div>
							))
						}
						{
							'Amministratore Ente' in tipiDisponibili && tipiDisponibili['Amministratore Ente'].map((x) => (
								<div key={x.ente.intestatario.codiceFiscale} className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Amministratore ente
										<br />
										<b>{x.ente.intestatario.denominazione}</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToBackoffice(x.id)}>SELEZIONA</button>
									</div>
								</div>
							))
						}
					</div>
					{
						'Admin' in tipiDisponibili && (
							<div className="bg-lightgrey pl-5 pr-5 pt-3 pb-3 text-center">
								<b>Amministrazione di pagoPA</b>
							</div>
						)
					}
					<div className="d-flex flex-wrap align-content-center m-5" style={{ rowGap: "34px", columnGap: "34px" }}>
						{
							'Admin' in tipiDisponibili && (
								<div className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Amministrazione
										<br />
										<b>Gestore di Sistema</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToBackoffice(tipiDisponibili.Admin[0].id)}>SELEZIONA</button>
									</div>
								</div>
							)
						}
						{
							'Interessi Legali' in tipiDisponibili ? (
								<div className="d-flex flex-column border rounded p-3" style={{ minHeight: "140px", width: "340px" }}>
									<div className="ml-2 text-uppercase">
										Amministrazione
										<br />
										<b>Gestore Interessi legali</b>
									</div>
									{/* eslint-disable-next-line */}
									<div align="right" className="mt-auto">
										<button type="button" className="btn btn-primary" onClick={goToBackoffice(tipiDisponibili["Interessi Legali"][0].id)}>SELEZIONA</button>
									</div>
								</div>
							) : ''
						}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Role;
