/* eslint-disable import/prefer-default-export */
import configuration from '../../configuration';
import useToken from "../../Hooks/useToken";

const deleteApi = async (query, items, ente, token) => {
	const enteHeader = ente ? { Ente: ente } : {};

	const response = await fetch(`${configuration.serverAddress}${query}`, {
		method: 'DELETE',
		body: JSON.stringify(items),
		headers: new Headers({
			Authorization: `Bearer ${token}`,
			...enteHeader,
			...configuration.defaultHeaders
		})
	});

	return response.status === 200;
};

export { deleteApi };
