export const createSimplifiedStructure = (struttura) => ({
	nome: struttura.nome,
	cdr: struttura.cdr,
	cdrEnte: struttura.cdrEnte,
	codiceEnte: struttura.codiceEnte,
	codiceTributo: struttura.codiceTributo,
	padre: struttura.padre && createSimplifiedStructure(struttura.padre),
	figli: []
});

export const findDeep = (strutture, key) => {
	const currFound = strutture.find((str) => str.cdr === key);
	if (currFound) return currFound;
	// eslint-disable-next-line
	for (const struttureElement of strutture) {
		const currFoundCh = findDeep(struttureElement.figli, key);
		if (currFoundCh) return currFoundCh;
	}
	return undefined;
};

export const mapDeep = (strutture, fn) => {
	let newStrutture = [];
	// eslint-disable-next-line
	for (const str of strutture) {
		const strModified = fn(str);
		const strChModified = {
			...strModified,
			figli: mapDeep(strModified.figli, fn)
		};
		newStrutture = [...newStrutture, strChModified];
	}
	return newStrutture;
};

export const getRootStructures = (strutture) => {
	// - Semplifico gli oggetti delle strutture e li isolo da quelli precedenti
	const simplifiedStructures = strutture.map(createSimplifiedStructure);

	// - Separo le strutture root da quelle con un padre
	const [root, pending] = simplifiedStructures.reduce(([r, p], struttura) => (struttura.padre
		? [r, [...p, struttura]] : [[...r, struttura], p]), [[], []]);

	// - Salvo i riferimenti per fare più iterazioni sulla stessa struttura
	let rootIter = root;
	let pendIter = pending;

	// - Ad ogni iterazione porto le strutture
	// mancanti in pending e le rielaboro tutte fino a consumarle
	while (pendIter.length > 0) {
		[rootIter, pendIter] = pendIter.reduce(([r, p], struttura) => {
			// - Cerco se è presente il padre nelle strutture root
			const foundParent = findDeep(r, struttura.padre.cdr);

			if (foundParent) {
				// - Aggiungo la struttura ai figli del padre
				const newRoot = mapDeep(r, (el) => (el.cdr === struttura.padre.cdr
					? { ...el, figli: [...el.figli, struttura] }
					: el));
				return [newRoot, p.filter((el) => el.cdr !== struttura.cdr)];
			}

			// - Aggiungo la struttura senza padre alle strutture root
			if (struttura.padre.padre) {
				if (p.some((x) => x.cdr === struttura.padre.cdr)) return [r, p];
				return [r, [...p, struttura.padre]];
			}
			return [[...r, struttura.padre], p];
		}, [rootIter, pendIter]);
	}
	return rootIter;
};
