import DataLabel from "../../Utils/DataLabel";

export default {
	numeroRata: new DataLabel("Numero Rata", "numeroRata"),
	iuv: new DataLabel("IUV", "iuv"),
	scadenza: new DataLabel("Scadenza", "scadenza"),
	inizioValidita: new DataLabel("Inizio Validità", "inizioValidita"),
	fineValidita: new DataLabel("Fine Validità", "fineValidita"),
	causalePendenza: new DataLabel("Causale Pendenza", "causalePendenza"),
	codiceDebito: new DataLabel("Codice Debito", "codiceDebito"),
	annoRiferimento: new DataLabel("Anno Riferimento", "annoRiferimento"),
	causaleDebito: new DataLabel("Causale Debito", "causaleDebito"),
	note: new DataLabel("Note", "note"),
	denominazioneEnte: new DataLabel("DenominazioneEnte", "denominazioneEnte"),
	denominazioneTributo: new DataLabel("Denominazione Tributo", "denominazioneTributo"),
	cdrStruttura: new DataLabel("CDR Struttura", "cdrStruttura"),
	nomeStruttura: new DataLabel("Nome Struttura", "nomeStruttura"),
	descrizioneStruttura: new DataLabel("Descrizione Struttura", "descrizioneStruttura"),
	codiceFiscaleDeb: new DataLabel("Codice Fiscale Debitore", "codiceFiscaleDeb"),
	partitaIva: new DataLabel("Partita Iva", "partitaIva"),
	nome: new DataLabel("Nome", "nome"),
	cognome: new DataLabel("Cognome", "cognome"),
	ragioneSociale: new DataLabel("Ragione Sociale", "ragioneSociale"),
	codiceDettaglio: new DataLabel("Codice Dettaglio", "codiceDettaglio"),
	tipo: new DataLabel("Tipo", "tipo"),
	importo: new DataLabel("Importo", "importo"),
	stato: new DataLabel("Stato", "stato"),
	descrizione: new DataLabel("Descrizione", "descrizione"),
	capitoloEntrata: new DataLabel("Capitolo Entrata", "capitoloEntrata"),
	codiceCapitoloBilancio: new DataLabel("Codice Capitolo Bilancio", "codiceCapitoloBilancio"),
	commisionePa: new DataLabel("Commisione PA", "commissionePa"),
	posizioneAccertamento: new DataLabel("Posizione Accertamento", "posizioneAccertamento"),
	codiceAccertamento: new DataLabel("Codice Accertamento", "codiceAccertamento"),
	ibanBeneficiario: new DataLabel("IBAN Beneficiario", "ibanBeneficiario")
};
