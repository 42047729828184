import { Box } from "@mui/material";

export default function SpinnyPage({ addStyle }) {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 10,
		...addStyle
	};

	return (
		<Box sx={style}>
			<div className="pageLoader" />
		</Box>
	);
}
