import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function EnteLogoInput({ value, onChange }) {
	const [logoPreview, setLogoPreview] = useState('');

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		accept: 'image/svg+xml',
		onDrop: (acceptedFiles) => {
			const reader = new FileReader();
			reader.readAsText(acceptedFiles[0], 'UTF-8');
			reader.onload = (evt) => {
				onChange(evt.target.result);
			};
		}
	});

	useEffect(() => {
		URL.revokeObjectURL(logoPreview);
		if (value) setLogoPreview(URL.createObjectURL(new Blob([value], { type: 'image/svg+xml' })));
		else setLogoPreview(null);
	}, [value]);

	const baseStyle = {
		flex: 1, display: 'inline-flex', flexDirection: 'column', alignItems: 'center', padding: '20px',
		borderWidth: 2, borderRadius: 2, borderColor: '#eeeeee', borderStyle: 'dashed',
		backgroundColor: '#fafafa', color: '#bdbdbd', outline: 'none',
		transition: 'border .24s ease-in-out', userSelect: 'none', cursor: 'pointer'
	};

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? { borderColor: '#2196f3' } : {}),
		...(isDragAccept ? { borderColor: '#00e676' } : {}),
		...(isDragReject ? { borderColor: '#ff1744' } : {})
	}), [isDragActive, isDragReject, isDragAccept]);

	return (
		<div className="mt-5 d-flex flex-row">
			{logoPreview && (<img src={logoPreview} alt="" height={100} className="mr-4" />)}
			{logoPreview && (<Button type="button" onClick={() => onChange && onChange(null)}>Reset logo</Button>)}
			{!logoPreview && (
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Trascina il file SVG o clicca per caricare il logo.</p>
				</div>
			)}
		</div>
	);
}
