/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const tablesSlice = createSlice({
	name: 'tables',
	initialState,
	reducers: {
		setOrder: (state, { payload: { tableName, order } }) => {
			state[tableName] = state[tableName] ?? { order: [], sort: [], visible: [] };
			state[tableName].order = order;
		},
		setSort: (state, { payload: { tableName, sort } }) => {
			state[tableName] = state[tableName] ?? { order: [], sort: [], visible: [] };
			state[tableName].sort = sort;
		},
		setVisible: (state, { payload: { tableName, visible } }) => {
			state[tableName] = state[tableName] ?? { order: [], sort: [], visible: [] };
			state[tableName].visible = visible;
		},
		setAllPreferences: (state, { payload }) => {
			state = payload;
			return state;
		}
	}

});

export const { setOrder, setSort, setVisible, setAllPreferences } = tablesSlice.actions;

export default tablesSlice.reducer;
