import React from "react";
import { TextField } from "@mui/material";

export default function CampoEmail({ value, onChange, campo }) {
	return (
		<TextField
			type="email"
			variant="standard"
			style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }}
			label={campo.label}
			value={value}
			onChange={(v) => onChange(v.target.value)}
		/>
	);
}
