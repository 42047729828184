/* eslint-disable react/jsx-one-expression-per-line */

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function ListaCard(props) {
	const {
		risultatiTotali,
		elementi
	} = props;

	return (
		<>
			<div className="row d-none d-md-block my-4">
				<div className="col-12">{risultatiTotali} {risultatiTotali === 1 ? "risultato" : "risultati"}</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="row">
						{elementi}
					</div>
				</div>
			</div>
		</>
	);
}
