import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function AzioneSetTipoDebitore({ onDelete, value, onChange, campi }) {
	const cfIva = campi.find((x) => x["@class"].split(".").pop() === "CampoCFIVA");
	return (
		<div className="d-flex flex-row bg-lightblue rounded mt-2 mb-2 pl-3 pr-3 pb-2 pt-1 align-middle">
			<IconButton size="small" className="mr-2" onClick={onDelete}>
				<i className="fas fa-fw fa-trash" />
			</IconButton>

			<FormControl variant="standard" className="ml-3" style={{ width: "300px" }}>
				{cfIva
					&& (
						<>
							<InputLabel>Campo</InputLabel>
							<Select variant="standard" value={value.nomeCampoTarget} onChange={(v) => onChange({ ...value, nomeCampoTarget: v.target.value })}>
								{campi.filter((x) => x["@class"].split(".").pop() === "CampoCFIVA").map((v) => (
									<MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>))}
							</Select>
						</>
					)}
			</FormControl>

			<FormControl variant="standard" className="w-100 ml-5" style={{ width: "200px" }}>
				<span style={{ height: "16px" }} />
				{cfIva
					? (
						<Select variant="standard" value={value.valore} onChange={(v) => onChange({ ...value, valore: v.target.value })}>
							<MenuItem value="Entrambi" defaultChecked>Entrambi</MenuItem>
							<MenuItem value="PersonaFisica">Persona fisica</MenuItem>
							<MenuItem value="PersonaGiuridica">Persona giuridica</MenuItem>
						</Select>
					) : (<p>Nessun campo Debitore presente nel form</p>)}
			</FormControl>
		</div>
	);
}
