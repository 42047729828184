import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function StatoTributo({ control, nullItem = false }) {
	return (
		<FormControl variant="standard" className="w-100">
			<InputLabel id="statoTributo">Stato tributo</InputLabel>
			<Controller
				name="stato"
				defaultValue="Attivo"
				control={control}
				render={({ field: { onBlur, onChange, value }, fieldState: { invalid } }) => (
					<Select
						labelId="statoTributo-label"
						label="Stato tributo"
						value={value || ''}
						onChange={onChange}
						onBlur={onBlur}
						error={invalid}
					>
						{nullItem && (<MenuItem>Nessun filtro</MenuItem>)}
						<MenuItem value="Attivo">Attivo</MenuItem>
						<MenuItem value="Disattivo">Disattivo</MenuItem>
						<MenuItem value="Nascosto">Nascosto</MenuItem>
					</Select>
				)}
			/>
		</FormControl>
	);
}
