import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<div className="pageNotFound mt-4">
			<div>
				<h3>404</h3>
				<h1>Pagina non trovata</h1>
				<h2>ci dispiace dell’inconveniente riprova più tardi</h2>
				<Link to="/">
					<button type="button" className="btn btn-outline-primary backHome">
						Torna alla homepage
					</button>
				</Link>
			</div>
			<img src="./images/error.png" alt="Risorsa non trovata" />
		</div>
	);
}
