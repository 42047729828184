import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';

import Role from './Role';
import Redirect from './Redirect';

export default function Authentication() {
	const { path, url } = useRouteMatch();

	// - Setto il colore principale di bootstrap
	const root = document.documentElement;
	root.style.setProperty('--primary', '#1d2d7e');

	return (
		<>
			<main>
				<Route exact path={path}><Redirect /></Route>
				<Route exact path={`${path}/cambiaRuolo`}><Role ignoreSingleRole /></Route>
				<Route exact path={`${path}/ruolo/:jwt`}><Role /></Route>
				<Route exact path={`${path}/ruolo`}><Role /></Route>
				<Route exact path={`${path}/logout`}><Redirect logout /></Route>
			</main>
		</>
	);
}
