/* eslint-disable class-methods-use-this */
import * as yup from "yup";

export default class BasePlugin {
	/**
	 * Configurazione del tributo sul plugin in questione
	 */
	configuration: {};

	/**
	 * Istanzia il plugin con la configurazione del tributo.
	 * @param configuration Configurazione del tributo relativa a questo plugin.
	 */
	constructor(configuration) {
		this.configuration = configuration;
	}

	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {};
	}

	/**
	 * Ritorna la struttura di validazione per il form di questo plugin nel portale.
	 * @param tributo Le informazioni del tributo.
	 * @returns {*}
	 */
	portalPaymentValidation() {
		return yup.object();
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel portale.
	 * @returns {JSX.Element}
	 */
	portalPaymentForm() {
		return <></>;
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	portalPaymentSubmit({ formValues, pluginData }) {
		return { formValues, pluginData };
	}

	/**
	 * Renderizza il riepilogo dei dati di pagamento.
	 * @returns {JSX.Element}
	 */
	paymentSummary({ debit, config, data, path, viewOnly }) {
		return <></>;
	}

	/**
	 * Componente chiamato durante il rendering del form da
	 * compilare nei pagamenti spontanei (Portale).
	 * @param formWatch Contiene lo stato del form.
	 * @param register Permette di registrare nuovi input nel form.
	 * @param control Permette di registrare input facendo uso del From hook <Controller>.
	 * @returns {JSX.Element}
	 */
	paymentSummaryButtons({ debit, config, data, path, viewOnly, payeeView }) {
		return <></>;
	}

	/**
	 * Ritorna i valori di default per i campi del portal payment form.
	 * @param tributo Le informazioni del tributo.
	 * @param defaultInitData Informazioni opzionali da passare per l'inizializzazione.
	 * @returns {*}
	 */
	backofficePaymentDefaults(tributo, defaultInitData) {
		return yup.object();
	}

	/**
	 * Ritorna la struttura di validazione per il form di questo plugin nel backoffice.
	 * @param tributo Le informazioni del tributo.
	 * @returns {*}
	 */
	backofficePaymentValidation() {
		return yup.object();
	}

	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentForm({ config }) {
		return <></>;
	}


	/**
	 * Renderizza il form per i pagamenti personalizzati nel backoffice.
	 * @returns {JSX.Element}
	 */
	backofficePaymentFormEditor({ config }) {
		return <></>;
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentSubmit({ formValues, pluginData }) {
		return { formValues, pluginData };
	}

	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentEditorSubmit(fields, baseDebit) {
		return baseDebit;
	}

	/**
	 * Renderizza il form di configurazione del plugin.
	 * @returns {JSX.Element}
	 */
	configurationForm({ path }) {
		return <></>;
	}
}
