import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from "redux-saga";

import paginationReducer from './Pagination';
import authenticationReducer from './Authentication';
import cartReducer from './Cart';
import tablesReducer from './Tables';
import localStateReducer from './LocalState';
import { ricercaReducer } from './Ricerca';
import { createSagas } from "./Sagas";

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ["ricerca", "authentication"]
};

const persistAuthConfig = {
	key: 'authentication',
	storage,
	blacklist: ["state"]
};

const reducer = combineReducers({
	cart: cartReducer,
	tables: tablesReducer,
	pagination: paginationReducer,
	authentication: persistReducer(persistAuthConfig, authenticationReducer),
	ricerca: ricercaReducer,
	localState: localStateReducer
});

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducer);

const Store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
		}
	}).concat(sagaMiddleware)
});

const allSagas = createSagas();
sagaMiddleware.run(allSagas);

export const persistor = persistStore(Store);

export default Store;
