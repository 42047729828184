import Ricerca from '../Components/Common/Ricerca';
import ListaEnti from '../Components/Enti/ListaEnti';

export default function Paga() {
	return (
		<div>
			<Ricerca />
			<ListaEnti />
		</div>
	);
}
