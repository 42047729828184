import { IconButton } from "@mui/material";

export default function ButtonDescription({ label, onClick, className, description }) {
	return (
		<>
			<div className="button-description">
				<IconButton aria-label={label} onClick={onClick}>
					<i className={className} />
				</IconButton>
				<br />
				<p className="button-description-text">
					{description}
				</p>
			</div>
		</>
	);
}
