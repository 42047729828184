/* eslint-disable import/prefer-default-export */

import { all, put, takeLatest } from "redux-saga/effects";

import { createRequest } from "../../Utils/AxiosRequest";
import { FETCH_ALL_ENTI, FETCH_ALL_STRUTTURE, FETCH_ALL_TRIBUTI } from "./actionTypes";
import {
	fetchAllEntiLoading,
	fetchAllEntiSuccess,
	fetchAllStruttureLoading,
	fetchAllStruttureSuccess, fetchAllTributiLoading,
	fetchAllTributiSuccess
} from "./actions";
import { preparaListaEnti, preparaListaStrutture, preparaListaTributi } from "./functionalities";
// import { listaEntiResponse, listaTributiResponse } from "./mock";

function* fetchAllEntiWorker(action) {
	yield put(fetchAllEntiLoading());
	try {
		const response = yield createRequest("POST", "/api/ente/query-public", '', null, {
			page: 0,
			pageSize: 40,
			query: action.search.query,
			tributo: action.search.tributo && { codiceTributo: action.search.tributo },
			queryParameters: ["intestatario.denominazione"],
			orderBy: [{ field: "intestatario.denominazione", desc: false }],
			filtraNoTributi: true
		});

		if (response.status === 200) {
			const enti = preparaListaEnti(response.data.data);
			yield put(fetchAllEntiSuccess(enti));
		} else throw response.message;
	} catch (e) {
		// console.error("Errore durante il caricamento della lista degli enti:", e);
		yield put(fetchAllEntiSuccess([], true));
	}
}

function* fetchAllTributiWorker(action) {
	yield put(fetchAllTributiLoading());
	try {
		const response = yield createRequest("POST", "/api/tributo/query-public", '', null, {
			page: 0,
			pageSize: 40,
			query: action.search.query,
			ente: { codiceEnte: action.search.ente },
			struttura: action.search.struttura && { codiceStruttura: action.search.struttura },
			orderBy: [{ field: "denominazione", desc: false }],
			queryParameters: ["denominazione"]
		});

		if (response.status === 200) {
			const tributi = preparaListaTributi(response.data.data);
			yield put(fetchAllTributiSuccess(tributi));
		} else throw response.message;
	} catch (e) {
		// console.error("Errore durante il caricamento della lista dei tributi:", e);
		yield put(fetchAllTributiSuccess([], true));
	}
}

function* fetchAllStruttureWorker(action) {
	yield put(fetchAllStruttureLoading());
	try {
		const response = yield createRequest("POST", "/api/struttura/query-public", '', null, {
			page: 0,
			pageSize: 40,
			query: action.search.query,
			ente: { codiceEnte: action.search.ente },
			tributo: action.search.tributo && { codiceTributo: action.search.tributo },
			queryParameters: ["descrizione"],
			orderBy: [{ field: "descrizione", desc: false }],
			senzaTributi: false
		});
		if (response.status === 200) {
			const strutture = preparaListaStrutture(response.data.data, 0);
			yield put(fetchAllStruttureSuccess(strutture));
		} else throw response.message;
	} catch (e) {
		// console.error("Errore durante il caricamento della lista delle strutture:", e);
		yield put(fetchAllStruttureSuccess([], true));
	}
}

function* fetchAllEntiWatcher() {
	yield takeLatest(FETCH_ALL_ENTI, fetchAllEntiWorker);
}

function* fetchAllTributiWatcher() {
	yield takeLatest(FETCH_ALL_TRIBUTI, fetchAllTributiWorker);
}

function* fetchAllStruttureWatcher() {
	yield takeLatest(FETCH_ALL_STRUTTURE, fetchAllStruttureWorker);
}

export function* ricercaWatchers() {
	yield all([fetchAllEntiWatcher(), fetchAllTributiWatcher(), fetchAllStruttureWatcher()]);
}
