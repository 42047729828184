import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, Controller, useForm, useWatch } from "react-hook-form";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment as DateAdapter } from "@mui/x-date-pickers/AdapterMoment";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	TextField,
	Typography
} from "@mui/material";
import { Alert } from "@mui/lab";
import { useFetch } from "../../Hooks/useFetch";
import SpinnyPage from "../Components/SpinnyPage";
import { AnagraficaDebitore, RateForm } from "../Components/Forms/UtilitiesDebito";
import PluginManager from "../../Plugins/PluginManager";
import configuration from "../../configuration";
import handleErrors from "../Utils/HandleErrors";
import useToken from "../../Hooks/useToken";
import StrutturaSelect from "../Components/Forms/StrutturaSelect";
import { removeEmptyObjects } from "../../Utils/Object";

export default function EditDebito({ account }) {
	const { ente, codice, tributo, altDebito } = useParams();

	const { data } = useFetch(`/debiti/${ente}/${tributo}/${codice}/${altDebito}`, null, 'GET', null);
	const [pluginExpanded, setPluginExpanded] = useState(false);
	const [submitState, setSubmitState] = useState('idle');
	const [result, setResult] = useState(null);
	const [copyingIuv, setCopyingIuv] = useState(false);
	const [copyingId, setCopyingId] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const jwt = useToken();

	const editForm = useForm({
		values: {
			...data,
			cdr: data?.struttura?.cdr,
			anagraficaDebitore: {
				...data?.anagraficaDebitore,
				personaFisica: !data?.anagraficaDebitore.ragioneSociale
			},
			pendenze: data?.pendenze.map((p) => ({
				...p,
				"@class": "it.ras.pagopa.shared.dto.pendenze.PendenzaPatchDto",
				dettagli: p.dettagli.map((d) => ({
					...d,
					"@class": "it.ras.pagopa.shared.dto.pendenze.DettaglioPatchDto"
				}))
			}))
		}
	});

	const strutture = useWatch({ control: editForm.control, name: "tributo.struttureAmmesse", defaultValue: [] });
	const pluginsConf = useWatch({ control: editForm.control, name: "tributo.plugins", defaultValue: [] });
	const pluginsData = useWatch({ control: editForm.control, name: "pluginData", defaultValue: [] });

	// - Recupero la configurazione del plugin e genero il plugin
	const pluginManager = new PluginManager();
	const plugins = pluginsConf?.map((conf) => pluginManager.getPlugin(conf.codicePlugin, conf)) ?? [];

	const filteredStrutture = useMemo(() => {
		if (account?.strutture && Object.keys(account.strutture).length > 0) {
			const struttureOperatore = Object.keys(account.strutture).map((x) => x.replaceAll("-", "."));
			return strutture.filter((x) => struttureOperatore.includes(x.cdr));
		}
		return strutture;
	}, [account.strutture, strutture]);

	const submitForm = async (formData) => {
		editForm.clearErrors();
		setResult(null);

		const pluginCampiIdx = formData.pluginData.findIndex((plugin) => plugin.codicePlugin === "CAMPI");
		const { pluginData } = plugins?.reduce((acc, plugin, i) => plugin.backofficePaymentSubmit(acc), {
			formValues: pluginCampiIdx >= 0 ? formData.pluginData[pluginCampiIdx].valueMap : {},
			pluginData: formData.pluginData
		});

		const modData = {
			...formData,
			codiceTributo: formData.tributo.codiceTributo,
			pluginData
		};

		const codiceEnte = modData.ente.codiceEnte ?? null;
		const selectedEnte = codiceEnte ? { Ente: codiceEnte } : {};

		const response = await fetch(`${configuration.serverAddress}/debiti/edit/${codiceEnte}/${modData.tributo.codiceTributo}/${modData.codiceDebito}/${modData.altDebito}`, {
			method: 'PATCH',
			body: JSON.stringify(removeEmptyObjects(modData)),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...selectedEnte,
				...configuration.defaultHeaders
			})
		});

		const responseData = await response.json();

		if (response.status >= 400) {
			if (responseData.dettaglio !== undefined) {
				responseData.dettaglio.forEach((errore) => editForm.setError(errore.field, {
					description: errore.description }));
			} else handleErrors(responseData, editForm.setError);
			return;
		}

		setResult(responseData);
	};

	const copyToCliboard = (clipData) => {
		const iuv = clipData === "iuv";

		navigator.clipboard.writeText(iuv ? result.pendenze[0].iuv : result.codiceDebito).then(() => {
			if (iuv) setCopyingIuv(true);
			else setCopyingId(true);

			setTimeout(() => {
				if (iuv) setCopyingIuv(false);
				else setCopyingId(false);
			}, 2000);
		}, (err) => {
			console.error('Could not copy text: ', err);
		});
	};

	const downloadNotice = async () => {
		setDownloading(true);
		const response = await fetch(`${configuration.serverAddress}/debiti/avviso`, {
			method: 'POST',
			body: JSON.stringify([{ codiceEnte: result.ente.codiceEnte, iuv: result.pendenze[0].iuv }]),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setDownloading(false);
	};

	const visitLeaf = (obj, oldKey) => Object.keys(obj)
		.filter((k) => k !== "ref" && k !== "title")
		.map((key) => ((obj[key] != null && typeof obj[key] === 'object' && obj[key].constructor === Object) // eslint-disable-line
			? visitLeaf(obj[key], key)
			: Array.isArray(obj[key])
				? obj[key].map((v, i) => visitLeaf(v, i))
				: { key: oldKey, value: obj[key] }));

	if (!data) return <SpinnyPage />;

	console.log(editForm.watch());

	return (
		<form onSubmit={editForm.handleSubmit(submitForm)}>
			<FormProvider {...editForm}>
				<Box className="white-bg m-3 p-3 mt-4">
					<div className="d-flex flex-row align-items-center">
						<div>
							<h1>
								<span>Editing debito: </span>
								{data.codiceDebito}
							</h1>
							{`${data.ente.intestatario.denominazione} - ${data.tributo.denominazione}`}
						</div>
					</div>
					<Divider className="mt-3" />
					<div className="d-flex flex-row mt-4">
						<div style={{ width: "570px" }} className="d-flex flex-column">
							<AnagraficaDebitore />
							<TextField {...editForm.register('note')} label="Note del debito" multiline className="w-100 mt-4" />
						</div>
						<div style={{ width: "570px" }} className="d-flex flex-column ml-5 mt-3">
							{
								(filteredStrutture?.length ?? 0) > 0 && (
									<Controller name="cdr" render={({ field: { onChange, value } }) => <StrutturaSelect onChange={onChange} required={(filteredStrutture?.length ?? 0) > 0} value={value} strutture={filteredStrutture} />} />
								)
							}
							{(filteredStrutture?.length ?? 0) <= 0
								&& (
									<Controller name="cdr" render={({ field: { onChange, value } }) => <TextField onChange={onChange} variant="standard" label="CDR" className="w-100" value={value} />} />
								)
							}
							<TextField {...editForm.register('causale')} required label="Causale" inputProps={{ maxLength: 256 }} variant="standard" className="mb-3 mt-2 w-100" />
							<TextField {...editForm.register('annoRiferimento')} required label="Anno di riferimento" type="number" inputProps={{ maxLength: 4 }} variant="standard" className="mt-2 w-100" />
							<LocalizationProvider dateAdapter={DateAdapter}>
								<Controller
									name="dataInsorgenza"
									defaultValue={null}
									render={({ field: { onChange, value } }) => (
										<DesktopDatePicker
											label="Data di insorgenza"
											inputFormat="DD/MM/yyyy"
											value={value && moment(value, "yyyy-MM-DD")}
											onChange={(v) => onChange(v?.format("yyyy-MM-DD"))}
											renderInput={(params) => <TextField className="w-100 mt-3" {...params} />}
										/>
									)}
								/>
							</LocalizationProvider>
						</div>
					</div>
					{
						plugins?.length > 0 && (
							<div className="mt-4">
								<h3>Plugin</h3>
								<hr />
								{
									plugins?.map((plugin, i) => {
										let index = pluginsData?.findIndex((pv) => pv.codicePlugin === plugin.configuration.codicePlugin);
										if (index < 0) index = pluginsData?.length ?? 0;
										return (
											<Accordion
												key={`${i + 1}`}
												expanded={pluginExpanded === i}
												onChange={(event, isExpanded) => setPluginExpanded(isExpanded ? i : false)}
											>
												<AccordionSummary>
													<Typography sx={{ width: '33%', flexShrink: 0, lineHeight: "28px" }}>{plugin.configuration.nome}</Typography>
													<Typography sx={{ color: 'text.secondary', lineHeight: "28px" }}>{plugin.configuration.descrizione}</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<plugin.backofficePaymentFormEditor key={plugin.configuration.codicePlugin} config={plugin.configuration} basePath={`pluginData.${index}`} edit />
												</AccordionDetails>
											</Accordion>
										);
									})
								}
							</div>
						)
					}
				</Box>
				<div className="ml-4 mr-4">
					<RateForm />
				</div>
			</FormProvider>

			<div className="ml-4 mr-4 mb-5" style={{ width: "1140px" }}>
				<Button type="submit" variant="contained" disabled={submitState === "loading"} onClick={() => editForm.clearErrors()} className="mt-5">CARICA</Button>
				{
					visitLeaf(editForm.formState.errors).flat(Infinity).map((v) => (
						<Alert severity="error" className="mt-3 w-100">
							{`${v.key}: ${v.value}`}
						</Alert>
					))
				}
				{
					result !== null && (
						<>
							<hr />
							<Alert severity="info" className="mt-3 w-100">
								<b>Pendenza aggiornata</b>
								<p>
									<b>IUV: </b>
									{result.pendenze[0].iuv}
								</p>
								<p>
									<b>ID Debito: </b>
									{result.codiceDebito}
								</p>
							</Alert>
							<div className="d-flex flex-row">
								<Button onClick={() => copyToCliboard("iuv")} variant="contained" className="mt-3 w-25 d-inline">{copyingIuv ? "Copiato" : "Copia IUV"}</Button>
								<Button onClick={() => copyToCliboard("id")} variant="contained" className="mt-3 ml-3 w-25 d-inline">{copyingId ? "Copiato" : "Copia ID"}</Button>
								<Button onClick={downloadNotice} variant="contained" className="mt-3 ml-3 w-50 d-inline">{downloading ? "In corso" : "Scarica avviso"}</Button>
							</div>
						</>
					)
				}
			</div>
		</form>
	);
}
