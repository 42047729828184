import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

export default function AzioneSetCE({ onDelete, value, onChange, campi }) {
	return (
		<div className="d-flex flex-row bg-lightblue rounded mt-2 mb-2 pl-3 pr-3 pb-2 pt-1 align-middle">
			<IconButton size="small" className="mr-2" onClick={onDelete}>
				<i className="fas fa-fw fa-trash" />
			</IconButton>

			<FormControl variant="standard" className="ml-3" style={{ width: "300px" }}>
				<InputLabel>Campo</InputLabel>
				<Select variant="standard" value={value.nomeCampoTarget} onChange={(v) => onChange({ ...value, nomeCampoTarget: v.target.value })}>
					{campi.filter((v) => v["@class"].split('.').pop() === "CampoImporto").map((v) => (<MenuItem value={v.nome} key={v.nome}>{v.label}</MenuItem>))}
				</Select>
			</FormControl>

			<FormControl variant="standard" className="w-100 ml-5" style={{ width: "200px" }}>
				<TextField
					type="string"
					variant="standard"
					style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }}
					value={value.valore}
					label="Valore"
					onChange={(v) => onChange({ ...value, valore: v.target.value })}
				/>
			</FormControl>
		</div>
	);
}
