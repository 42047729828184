import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EditableSelectList from "../../Components/EditableSelectList";

export default function CampoSelectSetting({ value, onChange }) {
	return (
		<div className="d-flex flex-column">
			<EditableSelectList value={value.valori} onChange={(v) => onChange({ ...value, valori: v })} className="w-100" />
			<FormControl variant="standard" className="w-100 mt-4">
				<InputLabel>Valore default</InputLabel>
				<Select className="w-100" variant="standard" value={value.defaultValue} onChange={(v) => onChange({ ...value, defaultValue: v.target.value })}>
					<MenuItem value="">Nessuno</MenuItem>
					{value.valori.map((v) => <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>)}
				</Select>
			</FormControl>
		</div>
	);
}
