import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";

export default function TimeCondition({ value, onChange, currentField }) {
	return (
		<div className="d-inline-flex flex-row flex-nowrap ml-5 align-items-center">
			<FormControl variant="standard" style={{ width: "200px" }}>
				<InputLabel>Operatore</InputLabel>
				<Select variant="standard" value={value.operatore} onChange={(v) => onChange({ ...value, operatore: v.target.value })}>
					<MenuItem value="Uguale">Now Uguale</MenuItem>
					<MenuItem value="Diverso">Now Diverso</MenuItem>
					<MenuItem value="Maggiore">Now maggiore</MenuItem>
					<MenuItem value="MaggioreUguale">Now maggiore o uguale</MenuItem>
					<MenuItem value="Minore">Now minore</MenuItem>
					<MenuItem value="MinoreUguale">Now minore o uguale</MenuItem>
				</Select>
			</FormControl>
			<FormControl variant="standard" className="ml-4 mt-3" style={{ width: "180px" }}>
				<TextField onChange={(v) => onChange({ ...value, valore: v.target.value })} value={value.valore} />
			</FormControl>
		</div>
	);
}
