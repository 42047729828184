import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Typography
} from "@mui/material";
import { Tooltip } from "antd";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useFetch } from "../../../Hooks/useFetch";
import configuration from "../../../configuration";
import handleErrors from "../../Utils/HandleErrors";
import EnteSelect from "../../Components/Forms/EnteSelect";
import BigDataTable from "../../Components/Tables/BigDataTable";
import DataTableColumn from "../../Components/Tables/DataTableColumn";
import Modal from "../../Components/Modal";
import SmallDataTable from "../../Components/Tables/SmallDataTable";
import useToken from "../../../Hooks/useToken";
import StyledDropzone from "../../Components/StyledDropzone";

export default function CaricamentoCsv({ account }) {
	const [submitState, setSubmitState] = useState('idle');
	const [openDetails, setOpenDetails] = useState(false);
	const [detailsData, setDetailsData] = useState(null);
	const [detailsErrorPage, setDetailsErrorPage] = useState(0);
	const [detailsMailErrorPage, setDetailsMailErrorPage] = useState(0);
	const [detailsPage, setDetailsPage] = useState(0);
	const [refreshUploads, setRefreshUploads] = useState(0);
	const [error, setError] = useState(null);
	const [query, setQuery] = useState({ page: 0, pageSize: 6, orderBy: [{ field: "id", desc: true }] });
	const [downloading, setDownloading] = useState(false);
	const { handleSubmit, control, setValue } = useForm();
	const enteOperatore = account.ente?.codiceEnte;
	const ente = enteOperatore ?? useWatch({ name: "ente", control });
	const jwt = useToken();

	const { data: uploadData } = useFetch('/debiti/import/state', enteOperatore ?? ente, 'POST', query ?? {}, refreshUploads);

	const refresher = useRef(null);

	useEffect(() => {
		const needRefreshes = uploadData?.data?.some((v) => v.stato === "InElaborazione"
			|| v.stato === "DaElaborare"
			|| v.stato === "ElaboratoNoAvvisi"
			|| v.stato === "AvvisiInElaborazione") ?? false;

		if (refresher.current == null && needRefreshes) {
			refresher.current = setInterval(() => {
				setRefreshUploads(Date.now());
			}, 2000);
		}

		if (refresher.current != null && !needRefreshes) {
			clearInterval(refresher.current);
			refresher.current = null;
		}
	}, [JSON.stringify(uploadData)]);

	useEffect(() => {
		const action = async () => {
			if (openDetails) {
				const response = await fetch(`${configuration.serverAddress}/debiti/import/${openDetails}`, {
					method: "GET",
					headers: new Headers({
						Authorization: `Bearer ${jwt}`,
						...configuration.defaultHeaders
					})
				});

				setDetailsData(await response.json());
			} else setDetailsData(null);
		};

		action();
	}, [openDetails]);

	const downloadNotices = async (id) => {
		setDownloading(id);
		const response = await fetch(`${configuration.serverAddress}/debiti/avviso/import/${id}`, {
			method: 'GET',
			headers: new Headers({ Authorization: `Bearer ${jwt}` })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		setDownloading(false);
	};

	const submitForm = (data) => {
		if (!data?.file) return;

		const form = new FormData();
		form.append('file', data.file);

		const codiceEnte = data.ente ?? null;
		const selectedEnte = codiceEnte ? { Ente: codiceEnte } : {};

		fetch(`${configuration.serverAddress}/debiti/import/csv`, {
			method: 'POST',
			body: form,
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...selectedEnte
			})
		}).then(async (response) => {
			if (response.status === 201) {
				setValue("file", null);
				setRefreshUploads(refreshUploads + 1);
				setSubmitState('idle');
			}
			if (response.status >= 400) {
				throw await response.json();
			}
		}).catch((err) => {
			setSubmitState('idle');
			handleErrors(err, setError);
		});
	};

	if (uploadData?.data) {
		uploadData.data = uploadData.data.map((v) => ({
			...v,
			percentualeCaricamento: v.percentualeCaricamento ?? (v.stato === "Elaborato" ? 100 : 0),
			numeroPendenzeElaborate: v.numeroPendenzeElaborate ?? 0,
			numeroPendenze: v.numeroPendenze ?? 0
		}));
	}

	const boxStyle = {
		position: 'absolute', top: '50%', left: '50%',
		maxHeight: "800px", overflowY: "scroll",
		transform: 'translate(-50%, -50%)',
		width: 900,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	return (
		<>
			{
				uploadData && (
					<>
						<Modal
							sx={{ width: 700 }}
							open={openDetails !== false}
							onClose={() => setOpenDetails(false)}
						>
							{detailsData && (
								<Box sx={boxStyle}>
									<div className="details-modal-header">
										<h2>
											Importazione numero #
											{detailsData?.idImportazione}
										</h2>
										<button className="close-button" type="button" label="exit" onClick={() => setOpenDetails(false)}>
											<i className="fas fa-times fa-2x" style={{ color: "dimgray" }} />
										</button>
									</div>
									<hr />
									<SmallDataTable
										onPageChange={setDetailsPage}
										page={detailsPage}
										data={detailsData?.importPendenze
											.map((x) => x).sort((firstEl, secondEl) => firstEl.riga - secondEl.riga)
											.slice(detailsPage * 10, detailsPage * 10 + 10)
											.map((x, id) => ({ ...x, id }))}
										pageSize={10}
										totalCount={detailsData?.importPendenze?.length}
										idField="id"
									>
										<DataTableColumn renderHeader={() => "Riga"} renderContent={(r) => r.riga} min />
										<DataTableColumn renderHeader={() => "Esito"} renderContent={(r) => r.esito} min />
										<DataTableColumn renderHeader={() => "IUV"} renderContent={(r) => r.iuv} />
									</SmallDataTable>
									{
										detailsData?.errors?.length > 0 && (
											<>
												<br />
												<br />
												<h4>Lista errori di caricamento pendenze</h4>
												<hr />
												<SmallDataTable
													onPageChange={setDetailsErrorPage}
													page={detailsErrorPage}
													data={detailsData?.errors
														?.sort((firstEl, secondEl) => firstEl.line > secondEl.line)
														?.slice(detailsErrorPage * 10, detailsErrorPage * 10 + 10)
														?.map((x, id) => ({ ...x, id })) ?? []}
													pageSize={10}
													totalCount={detailsData?.errors?.length}
													idField="id"
												>
													<DataTableColumn renderHeader={() => "Riga"} renderContent={(r) => r.line} min />
													<DataTableColumn renderHeader={() => "Colonna"} renderContent={(r) => r.field} min />
													<DataTableColumn renderHeader={() => "Descrizione"} renderContent={(r) => r.error} />
												</SmallDataTable>
											</>
										)
									}
									{
										detailsData?.errorsMails?.length > 0 && (
											<>
												<br />
												<br />
												<h4>Lista errori di invio Email Avviso</h4>
												<hr />
												<SmallDataTable
													onPageChange={setDetailsMailErrorPage}
													page={detailsMailErrorPage}
													data={detailsData?.errorsMails
														?.sort((firstEl, secondEl) => firstEl.line > secondEl.line)
														.slice(detailsMailErrorPage * 10, detailsMailErrorPage * 10 + 10)
														.map((x, id) => ({ ...x, id }))}
													pageSize={10}
													totalCount={detailsData?.errorsMails?.length}
													idField="id"
												>
													<DataTableColumn renderHeader={() => "Riga"} renderContent={(r) => r.line} min />
													<DataTableColumn renderHeader={() => "Colonna"} renderContent={(r) => r.field} min />
													<DataTableColumn renderHeader={() => "Descrizione"} renderContent={(r) => r.error} />
												</SmallDataTable>
											</>
										)
									}
								</Box>
							)}
						</Modal>
						<BigDataTable
							onPageChange={(page) => setQuery({ ...query, page })}
							page={uploadData.currentPage}
							data={uploadData.data}
							pageSize={uploadData.pageSize}
							totalCount={uploadData.totalResults}
							idField="idImportazione"
						>
							<DataTableColumn renderHeader={() => "ID"} renderContent={(r) => r.idImportazione} min />
							{/* eslint-disable-next-line */}
							<DataTableColumn renderHeader={() => "Caricamento"} renderContent={(r) => (<div align="center">{`${new Date(r.tsCreazione).toLocaleString()}`}</div>)} min />
							<DataTableColumn
								min
								renderHeader={() => "Stato"}
								renderContent={(r) => {
									switch (r.stato) {
										case "Timeout":
											return (<span style={{ borderRadius: "3px", background: "#bfc9d9", padding: "0 5px 0 5px" }}><b>Scaduto</b></span>);
										case "DaElaborare":
											return (<span style={{ borderRadius: "3px", background: "#bfc9d9", padding: "0 5px 0 5px" }}><b>Da elaborare</b></span>);
										case "Elaborato":
											return r.errors?.length > 0 ? (<span style={{ borderRadius: "3px", background: "#ff8c8c", padding: "0 5px 0 5px" }}><b>Errore</b></span>)
												: (<span style={{ borderRadius: "3px", background: "#6fed7e", padding: "0 5px 0 5px" }}><b>Elaborato</b></span>);
										case "InElaborazione":
											return (<span style={{ borderRadius: "3px", background: "#53befc", padding: "0 5px 0 5px" }}><b>In elaborazione</b></span>);
										case "ElaboratoNoAvvisi":
										case "AvvisiInElaborazione":
											return (<span style={{ borderRadius: "3px", background: "#fdbc6c", padding: "0 5px 0 5px" }}><b>Generazione avvisi</b></span>);
										default:
											return (<span style={{ borderRadius: "3px", background: "#bfc9d9", padding: "0 5px 0 5px" }}><b>{r.stato}</b></span>);
									}
								}}
							/>
							<DataTableColumn
								renderHeader={() => "Progresso"}
								renderContent={(r) => (
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Box sx={{ width: '100%', mr: 1 }}>
											<LinearProgress variant="determinate" value={r.percentualeCaricamento} />
										</Box>
										<Box sx={{ minWidth: 35 }}>
											<Typography variant="body2" color="text.secondary">{`${Math.round(r.percentualeCaricamento)}%`}</Typography>
										</Box>
									</Box>
								)}
							/>
							{/* eslint-disable-next-line */}
							<DataTableColumn renderHeader={() => "Elaborate"} renderContent={(r) => (<div align="center">{`${r.numeroPendenzeElaborate} / ${r.numeroPendenze}`}</div>)} min />
							<DataTableColumn
								renderHeader={() => "Avviso"}
								renderContent={(r) => r.numeroPendenzeElaborate > 0 && r.stato === "Elaborato" && (
									// eslint-disable-next-line
									<div align="center">
										<Tooltip title="Download Avvisi">
											<IconButton
												disabled={downloading !== false}
												onClick={() => downloadNotices(r.idImportazione)}
											>
												{
													downloading === r.idImportazione
														? (<i className="fas fa-fw fa-spinner fa-spin" />)
														: (<i className="fas fa-fw fa-file-download" />)
												}
											</IconButton>
										</Tooltip>
									</div>
								)}
								min
							/>
							<DataTableColumn
								renderHeader={() => "Dettagli"}
								renderContent={(r) => (
									// eslint-disable-next-line
									<div align="center">
										<IconButton onClick={() => setOpenDetails(r.idImportazione)}>
											<i className="fas fa-fw fa-info-circle" />
										</IconButton>
									</div>
								)}
								min
							/>
						</BigDataTable>
					</>
				)
			}
			<form onSubmit={handleSubmit(submitForm)} className="mt-5">
				<Controller
					render={({ field: { onChange, value } }) => (
						<StyledDropzone
							onChange={onChange}
							value={value}
							accept="text/csv"
							text="Premere per selezionare o trascinare un file CSV dentro il riquadro."
							singleImport
						/>
					)}
					name="file"
					control={control}
					defaultValue=""
				/>
				<div className="d-flex flex-row">
					{
						enteOperatore ? <></> : (
							<div style={{ width: "400px" }} className="mr-4">
								<Controller
									render={({ field: { onChange, value } }) => (
										<EnteSelect onChange={onChange} value={value} required />
									)}
									name="ente"
									control={control}
									defaultValue=""
								/>
							</div>
						)
					}
					<Button type="submit" className="mt-3" variant="contained">Carica</Button>
				</div>
			</form>
		</>
	);
}
