/* eslint-disable react/jsx-one-expression-per-line */

import { useCallback } from "react";
import TitleBar from './TitleBar';
import debounce from "../../../Backoffice/Utils/Debounce";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function TitleBarWithSearch(props) {
	const {
		titolo,
		sottotitolo,
		placeholderRicerca,
		contenutoAddizionale,
		searchChange
	} = props;

	let phRicerca = placeholderRicerca;
	if (!phRicerca) {
		phRicerca = 'Cerca';
	}

	const debounceOnSearch = useCallback(debounce(500, searchChange), []);

	const ricerca = (
		<>
			<input type="hidden" name="action" defaultValue="search" />
			<div id="title-bar-search" className="form-group">
				<div className="input-group">
					<input
						type="search"
						className="form-control"
						id="field-area-cerca"
						name="k"
						placeholder={phRicerca}
						defaultValue=""
						{...searchChange
							? { onChange: (e) => debounceOnSearch(e.target.value) }
							: {}}
					/>
				</div>
				{contenutoAddizionale}
			</div>
		</>
	);

	return (
		<TitleBar titolo={titolo} sottotitolo={sottotitolo} contenutoAddizionale={ricerca} />
	);
}
