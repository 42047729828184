import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function Compare({ value, onChange, currentField }) {
	return (
		<div className="d-inline-flex ml-5 align-items-center">
			<FormControl variant="standard" style={{ width: "200px" }}>
				<InputLabel>Operatore</InputLabel>
				<Select variant="standard" value={value.operatore} onChange={(v) => onChange({ ...value, operatore: v.target.value })}>
					<MenuItem value="Uguale">Uguale</MenuItem>
					<MenuItem value="Diverso">Diverso</MenuItem>
					{
						!["CampoCheckbox", "CampoCFIVA"].includes(currentField["@class"].split('.').pop()) ? [
							<MenuItem value="Maggiore">Maggiore</MenuItem>,
							<MenuItem value="MaggioreUguale">Maggiore o uguale</MenuItem>,
							<MenuItem value="Minore">Minore</MenuItem>,
							<MenuItem value="MinoreUguale">Minore o uguale</MenuItem>
						] : ""
					}
				</Select>
			</FormControl>
			<FormControl variant="standard" className="ml-2" style={{ width: "290px" }}>
				{
					currentField.component && React.createElement(currentField.component, {
						value: value.valore,
						onChange: (v) => onChange({ ...value, valore: v }),
						campo: currentField
					})
				}
			</FormControl>
		</div>
	);
}
