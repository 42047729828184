/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary, Button,
	FormControl,
	IconButton,
	InputLabel, MenuItem, Select,
	Typography
} from "@mui/material";
import JSONInput from "react-json-editor-ajrm";
import locale from 'react-json-editor-ajrm/locale/en';
import { useFieldArray } from "react-hook-form";
import PluginManager from "./PluginManager";

export default function PluginSettingsInterface() {
	const { fields, append, remove, replace } = useFieldArray({ name: "plugins" });
	const pluginManager = new PluginManager();

	const defaultPlugin = pluginManager.getAllPlugins()[0].codice;
	const [newPluginValue, setNewPluginValue] = useState(defaultPlugin);

	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<div>
				{
					fields?.map((conf, i) => {
						const plugin = pluginManager.getPlugin(conf.codicePlugin, conf);
						const ConfigurationForm = plugin ? plugin.configurationForm : <></>;
						return (
							<Accordion key={plugin.ordine} expanded={expanded === i} onChange={handleChange(i)}>
								<AccordionSummary>
									<IconButton size="small" className="mr-2" onClick={() => remove(i)}>
										<i className="fas fa-fw fa-trash" />
									</IconButton>
									<Typography sx={{ width: '33%', flexShrink: 0, lineHeight: "28px" }}>{plugin.configuration.nome}</Typography>
									<Typography sx={{ color: 'text.secondary', lineHeight: "28px" }}>{plugin.configuration.descrizione}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<ConfigurationForm path={`plugins.${i}`} />
								</AccordionDetails>
							</Accordion>
						);
					})
				}
			</div>
			<div className="d-inline-flex flex-row mt-4 pl-3">
				<FormControl variant="standard" className="mr-3">
					<InputLabel id="tipoPlugin">Tipologia plugin</InputLabel>
					<Select label="Tipologia plugin" style={{ width: "300px" }} value={newPluginValue} onChange={(e) => setNewPluginValue(e.target.value)}>
						{pluginManager.getAllPlugins().map((p) => <MenuItem key={p.codice} value={p.codice}>{p.nome}</MenuItem>)}
					</Select>
				</FormControl>
				<Button className="ml-auto ml-3" onClick={() => append(pluginManager.getPlugin(newPluginValue, { index: fields?.length ?? 0 }).emptyConfiguration())}>
					AGGIUNGI
				</Button>
			</div>

			<Accordion expanded={expanded === "EDITOR"} onChange={handleChange("EDITOR")} className="mt-4">
				<AccordionSummary>
					<Typography sx={{ width: '33%', flexShrink: 0 }}><b>Editor JSON</b></Typography>
					<Typography sx={{ color: 'text.secondary' }}>Permette di visualizzare e modificare la configurazione dei plugin.</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<JSONInput locale={locale} placeholder={fields} onChange={(v) => replace(v.jsObject)} width="813px" height="550px" />
				</AccordionDetails>
			</Accordion>
		</>
	);
}
