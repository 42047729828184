import DataLabel from "../../Utils/DataLabel";

export default {
	identificativoFlusso: new DataLabel("Identificativo flusso", "identificativoFlusso"),
	importo: new DataLabel("Importo", "importo"),
	dataOra: new DataLabel("Data ora ricezione flusso", "dataOra"),
	nomeMittente: new DataLabel("Nome mittente", "nomeMittente"),
	numeroPagamenti: new DataLabel("Numero pagamenti", "numeroPagamenti"),
	codiceEnte: new DataLabel("Codice ente", "codiceEnte")
};
