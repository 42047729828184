/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import SmallDataTable from "../Tables/SmallDataTable";
import DataTableColumn from "../Tables/DataTableColumn";

export default function MultiBenTable({ onChange, value }) {
	const [ibanPage, setIbanPage] = useState(0);

	function decombineIban(iban) {
		const ibansArr = [];
		if (iban.ibanBancario && iban.ibanBancario !== "") {
			ibansArr.push({
				partitaIva: iban.codiceFiscale,
				iban: iban.ibanBancario,
				tipo: "Bancario"
			});
		}
		if (iban.ibanPostale && iban.ibanPostale !== "") {
			ibansArr.push({
				partitaIva: iban.codiceFiscale,
				iban: iban.ibanPostale,
				tipo: "Postale"
			});
		}
		return ibansArr;
	}

	function combineIban(ibansArr) {
		return {
			ibanBancario: ibansArr.find((x) => x.tipo === "Bancario")?.iban ?? "",
			ibanPostale: ibansArr.find((x) => x.tipo === "Postale")?.iban ?? "",
			codiceFiscale: ibansArr[0]?.partitaIva ?? ""
		};
	}

	const ibans = value
		? Object.keys(value).reduce((acc, v) => [
			...acc, combineIban(value[v])
		], [])
		: [];

	const setIbans = (v) => {
		onChange(
			v.reduce((acc, iban) => (
				{ ...acc, [iban.codiceFiscale]: iban.codiceFiscale in ibans
					? [...acc[iban.codiceFiscale], ...decombineIban(iban)]
					: decombineIban(iban) }
			), {})
		);
	};

	/* useEffect(() => {
		const finalData = ibans.reduce((acc, iban) => (
			{ ...acc, [iban.codiceFiscale]: iban.codiceFiscale in ibans
					? [...acc[iban.codiceFiscale], ...decombineIban(iban)]
					: decombineIban(iban) }
		), {});
		onChange(finalData);
	}, [JSON.stringify(ibans)]);
	useEffect(() => {
		const formData = Object.keys(value).reduce((acc, v) => [
			...acc, combineIban(value[v])
		], []);
		if (!edit) setIbans(formData);
	}, [JSON.stringify(value)]); */

	return (
		<SmallDataTable
			onPageChange={(page) => setIbanPage(page)}
			page={ibanPage}
			data={ibans}
			pageSize={5}
			totalCount={ibans.length}
			idField="iban"
		>
			<DataTableColumn
				renderHeader={() => (
					<IconButton
						onClick={() => {
							setIbans([...ibans, { ibanBancario: "", ibanPostale: "", codiceFiscale: "" }]);
						}}
					>
						<i className="fas fa-fw fa-plus-circle" />
					</IconButton>
				)}
				renderContent={(r, i) => (
					<IconButton
						onClick={() => {
							setIbans(ibans.filter((x, ii) => ii != i));
						}}
					>
						<i className="fas fa-fw fa-minus-circle" />
					</IconButton>
				)}
				padding={10}
				min
			/>
			<DataTableColumn
				renderHeader={() => "IBAN BANCARIO"}
				renderContent={(r, i) => (
					<TextField
						value={ibans[i].ibanBancario}
						onChange={(v) => {
							setIbans(ibans.map((x, ii) => (ii == i
								? { ...ibans[ii], ibanBancario: v.target.value }
								: ibans[ii])));
						}}
						InputProps={{ disableUnderline: true }}
						label="IBAN"
						variant="standard"
						className="w-100"
					/>
				)}
				padding={10}
			/>
			<DataTableColumn
				renderHeader={() => "IBAN POSTALE"}
				renderContent={(r, i) => (
					<TextField
						value={ibans[i].ibanPostale}
						onChange={(v) => {
							setIbans(ibans.map((x, ii) => (ii == i
								? { ...ibans[ii], ibanPostale: v.target.value }
								: ibans[ii])));
						}}
						InputProps={{ disableUnderline: true }}
						label="IBAN"
						variant="standard"
						className="w-100"
					/>
				)}
				padding={10}
			/>
			<DataTableColumn
				renderHeader={() => "Codice Fiscale"}
				renderContent={(r, i) => (
					<TextField
						value={ibans[i].codiceFiscale}
						onChange={(v) => {
							setIbans(ibans.map((x, ii) => (ii == i
								? { ...ibans[ii], codiceFiscale: v.target.value }
								: ibans[ii])));
						}}
						InputProps={{ disableUnderline: true }}
						label="Codice Fiscale"
						variant="standard"
						className="w-100"
					/>
				)}
				padding={10}
			/>
		</SmallDataTable>
	);
}
