import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export default function ({ children }) {
	const accessToken = useSelector((state) => state.authentication.accessToken);
	const history = useHistory();

	if (!accessToken) history.push("/");

	return children;
}
