import { Controller } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { ClickAwayListener, Popper, TextField } from "@mui/material";
import DateRange from "react-date-range/dist/components/DateRange";
import Moment from "moment";
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export default function RangePicker({ nameStart, nameEnd, control, startText, endText }) {
	return (
		<Controller
			name={nameStart}
			control={control}
			render={({ field: { onChange: onChangeStart, value: valueStart } }) => (
				<Controller
					name={nameEnd}
					control={control}
					render={({ field: { onChange: onChangeEnd, value: valueEnd } }) => {
						const startDate = moment(valueStart, "yyyy-MM-DD");
						const endDate = moment(valueEnd, "yyyy-MM-DD");
						const calendar = {
							startDate: (valueStart && startDate.isValid() && startDate.toDate()) ?? new Date(),
							endDate: (valueEnd && endDate.isValid() && endDate.toDate()) ?? new Date(),
							key: "selection"
						};

						const [open, setOpen] = useState(false);
						const ref = useRef(null);

						const handleSelect = ({ selection }) => {
							const s = moment(selection.startDate);
							const e = moment(selection.endDate);
							onChangeStart(s.isValid() ? s.format("yyyy-MM-DD") : "");
							onChangeEnd(e.isValid() ? e.format("yyyy-MM-DD") : "");
						};

						return (
							<>
								<div ref={ref} style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
									<TextField
										value={valueStart ?? ""}
										placeholder={startText}
										InputProps={{ autocomplete: "one-time-code" }}
										onChange={(e) => onChangeStart(e.target.value)}
										onFocus={() => { setOpen(true); }}
										onClick={(e) => { e.stopPropagation(); }}
									/>
									<TextField
										value={valueEnd ?? ""}
										placeholder={endText}
										InputProps={{ autocomplete: "one-time-code" }}
										onChange={(e) => onChangeEnd(e.target.value)}
										onFocus={() => { setOpen(true); }}
										onClick={(e) => { e.stopPropagation(); }}
									/>
								</div>
								<Popper style={{ zIndex: 1400 }} open={open} anchorEl={ref.current} onClick={(e) => { e.stopPropagation(); }}>
									<ClickAwayListener onClickAway={() => { setOpen(false); }}>
										<DateRange
											date={handleSelect}
											ranges={[calendar]}
											onChange={handleSelect}
										/>
									</ClickAwayListener>
								</Popper>
							</>
						);
					}}
				/>
			)}
		/>
	);
}
