import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CampoCFIVASetting({ value, onChange }) {
	return (
		<div>
			<FormControl variant="standard" className="w-100">
				<InputLabel>Tipo di persona ammesso</InputLabel>

				<Select className="w-100" defaultValue="Entrambi" variant="standard" label="Tipo di persona ammesso" value={value.tipo} onChange={(v) => onChange({ ...value, tipo: v.target.value })}>
					<MenuItem value="Entrambi" defaultChecked>Entrambi</MenuItem>
					<MenuItem value="PersonaFisica">Persona fisica</MenuItem>
					<MenuItem value="PersonaGiuridica">Persona giuridica</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}
