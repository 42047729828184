import DataLabel from "../../Utils/DataLabel";

export default {
	id: new DataLabel("Identificavito feedback", "id"),
	backoffice: new DataLabel("Back-office", "backoffice"),
	valutazione: new DataLabel("Valutazione", "valutazione"),
	descrizione: new DataLabel("Descrizione", "descrizione"),
	dataFeedback: new DataLabel("Data feedback", "dataFeedback"),
	tributi: new DataLabel("Tributi", "tributi")
};

