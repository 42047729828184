import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleWithSearch from '../Components/Common/TitleBarWithSearch';
import { useFetch } from "../../Hooks/useFetch";
import ListaStrutture from '../Components/Strutture/ListaStrutture';
import SpinnyPage from "../../Backoffice/Components/SpinnyPage";

export default function Strutture() {
	const params = useParams();
	const codiceEnte = params?.ente ?? '';
	const codiceTributo = params?.servizio ?? '';
	const [search, setSearch] = useState('');

	const ente = useFetch('/ente/query-public', null, 'POST', {
		pageSize: 1,
		page: 0,
		codiceEnte
	}).data?.data[0] ?? null;

	const servizio = useFetch('/tributo/query-public', null, 'POST', {
		pageSize: 1,
		page: 0,
		ente: {
			codiceEnte
		},
		codiceTributo
	}).data?.data[0] ?? null;

	const breadcrumbs = [
		{ titolo: ente?.intestatario.denominazione ?? '...' },
		{ titolo: servizio?.denominazione ?? '...' },
		{ titolo: 'strutture organizzative' }
	];

	const comparator = (a, b) => {
		const nameA = a.nome.toUpperCase();
		const nameB = b.nome.toUpperCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		return 0;
	};

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleWithSearch
				titolo={ente && servizio ? `${servizio.denominazione} per ${ente.intestatario.denominazione}` : 'Caricamento...'}
				sottotitolo="Strutture disponibili"
				placeholderRicerca="Inserisci la struttura organizzativa"
				searchChange={(e) => setSearch(e)}
			/>
			<div style={{ minHeight: "700px" }}>
				{ente && servizio ? (
					<ListaStrutture
						searchString={search}
						codiceEnte={codiceEnte}
						codiceTributo={codiceTributo}
						strutture={servizio.struttureAmmesse.sort(comparator)}
					/>
				) : <SpinnyPage />}
			</div>
		</>
	);
}
