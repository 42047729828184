/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line*/
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import axios from "axios";
import { Button } from "design-react-kit";

import { Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { selectionArrayToObject, selectionObjectToArray } from "../../../Utils/Data";
import CampoRicerca from './CampoRicerca';
import CampoRicercaEnte from "./CampoRicercaEnte";
import RisultatiRicercaPagamento from "./RisultatiRicercaPagamento";
import InputConDescrizione from "./InputConDescrizione";
import { ENTE, keyEnte, keyStruttura, keyTributo, STRUTTURA, TRIBUTO } from "../../../Store/Ricerca";
import ModaleServizio from "../CarrelloPagamento/ModaleServizio";
import Captcha from "./Captcha";
import BoxHomepage from "./BoxHomepage";
import useToken from "../../../Hooks/useToken";
import HomepageCards from "./HomepageCards";

const tipiRicerca = [
	{
		tipo: 'pagamento',
		nome: 'Ente o Pagamento',
		placeholder: 'Inserire la denominazione dell’ente creditore o la tipologia di pagamento...'
	},
	{
		tipo: 'avviso',
		nome: 'Paga un avviso',
		placeholder: ['Ente creditore...', 'IUV o Codice Avviso...', 'Codice Fiscale o Partita IVA...']
	}
];

export default function Ricerca(props) {
	const {
		ifEmpty
	} = props;

	const user = useSelector((state) => state.authentication.user);

	const [tipoRicerca, setTipoRicerca] = useState(tipiRicerca[0]);
	const [selection, setSelection] = useState({});
	const [page, setPage] = useState(1);
	const pageSize = useSelector((state) => state.pagination.perPage);
	const [isOpenServ, setIsOpenServ] = useState(null);
	const [requiresEnte, setRequiresEnte] = useState(false);
	const [showAllTributi, setShowAllTributi] = useState(false);

	const {	handleSubmit, formState: { errors }, clearErrors, control, setError } = useForm();
	const selectionArray = selectionObjectToArray(selection);

	const isTablet = useMediaQuery("(max-width: 768px)");
	const isMobileLarge = useMediaQuery("(max-width: 425px)");
	const isMobileMedium = useMediaQuery("(max-width: 375px)");

	const [captcha, setCaptcha] = useState(null);

	const captchaRef = useRef();

	const jwt = useToken();

	useEffect(() => {
		if (!isOpenServ) {
			captchaRef.current?.reset();
			setCaptcha(null);
		}
	}, [isOpenServ]);

	const addToSelection = (type, el) => {
		setShowAllTributi(false);
		const newSel = { ...selection };
		let change = false;

		window.scrollTo(0, 0);

		switch (type) {
			case ENTE:
				newSel.ente = keyEnte(el);
				change = true;
				break;
			case TRIBUTO:
				newSel.tributo = keyTributo(el);
				change = true;
				break;
			case STRUTTURA:
				newSel.struttura = keyStruttura(el);
				change = true;
				break;
			default:
		}

		if (change) {
			setSelection(newSel);
		}
	};

	const getPagamento = (data) => {
		if (data.codice && data.codiceFiscale) {
			const axiosParams = {
				method: "GET",
				url: !requiresEnte
					? `/api/debiti/iuv/${data.codice}?cod_fiscale=${data.codiceFiscale}`
					: `/api/debiti/iuv/${data.ente}/${data.codice}?cod_fiscale=${data.codiceFiscale}`,
				headers: {
					"Content-Type": "application/json",
					...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
					...(captcha ? { Captcha: captcha } : {})
				}
			};
			axios(axiosParams)
				.then((response) => {
					const pagamento = response.data;
					setRequiresEnte(false);
					setIsOpenServ(pagamento);
				})
				.catch((e) => {
					captchaRef.current?.reset();
					setCaptcha(null);
					if (!requiresEnte && e.response.status === 409) setRequiresEnte(true);
					else if (e.response.status === 403) setError("general", { descrizione: "Non autorizzato a procedere." });
					else if (e.response.status === 404) setError("general", { descrizione: "Nessun risultato." });
					else setError("general", { descrizione: "Errore server." });
				});
		} else {
			setError("general", "Nessun risultato.");
		}
	};

	const expectResultsPagamento = tipoRicerca.tipo === 'pagamento' && (selection.ente || selection.tributo);

	const elementiMenu = tipiRicerca.map((m) => (
		<li
			className="nav-item nav-item-genSearch"
			key={`tipo-ricerca-${m.tipo}`}
			style={{ cursor: "pointer" }}
			onClick={() => {
				setTipoRicerca(m);
				setSelection({});
			}}
		>
			<span
				className={classNames({
					'nav-link': true,
					active: tipoRicerca.tipo === m.tipo
				})}
				style={{
					justifyContent: isMobileMedium ? "left" : "center"
				}}
			>
				{m.nome}
			</span>
		</li>
	));

	const links = [
		{
			testoRiga1: 'CONSULTARE LO STATO DI UNA',
			testoRiga2: 'POSIZIONE DEBITORIA',
			testoLink: 'Prosegui',
			tooltip: 'Consulta posizione debitorie',
			linkTo: user ? '/area-personale/posizione-debitoria' : '/posizione-debitoria'
		},
		{
			testoRiga1: 'CONSULTARE LO',
			testoRiga2: 'STORICO DEI PAGAMENTI',
			testoLink: 'Prosegui',
			tooltip: 'Consulta storico pagamenti',
			linkTo: user ? '/area-personale/storico-pagamenti' : '/storico-pagamenti'
		},
		{
			testoRiga1: 'CONSULTARE LA LISTA DEGLI',
			testoRiga2: 'ENTI CHE OFFRONO PAGAMENTI PAGOPA',
			testoLink: 'Visualizza',
			tooltip: 'Consulta gli Enti creditori',
			linkTo: '/enti'
		},
		{
			testoRiga1: 'CONSULTARE LA LISTA DEI',
			testoRiga2: 'PAGAMENTI CHE NON RICHIEDONO AUTENTICAZIONE',
			testoLink: 'Visualizza',
			tooltip: 'Consulta pagamenti senza autenticazione',
			linkTo: '/servizi/elenco'
		}
	];

	const boxes = links.map((l) => (
		<BoxHomepage
			key={l.linkTo}
			testoRiga1={l.testoRiga1}
			testoRiga2={l.testoRiga2}
			testoLink={l.testoLink}
			link={l.linkTo}
			tooltip={l.tooltip}
		/>
	));

	const elementiRicerca = tipoRicerca.tipo === 'pagamento' ? (
		<div className="col-12 pt-4 pl-4 pr-4 pb-4">
			<CampoRicerca
				placeholder={tipoRicerca.placeholder}
				selection={selectionArray}
				onSelectionChange={(c) => { setSelection(selectionArrayToObject(c)); }}
			/>
		</div>
	) : (
		<div>
			<form onSubmit={handleSubmit(getPagamento)} className="row">
				{requiresEnte && (
					<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"}>
						<Controller
							name="ente"
							control={control}
							defaultValue=""
							rules={{
								required: true
							}}
							render={({ field: { onChange } }) => (
								<CampoRicercaEnte
									placeholder={tipoRicerca.placeholder[0]}
									onSelectionChange={(c) => {	onChange(selectionArrayToObject(c).ente); }}
								/>
							)}
						/>
						{errors.ente && <div role="alert"><p style={{ color: "red" }} className="my-1">Il campo ente è obbligatorio</p></div>}
					</div>
				)}
				<div className={isMobileLarge ? "col-12 pt-4 pl-4 pr-4 pb-4" : isTablet ? "col-6 pt-4 pl-4 pr-4 pb-4" : "col-3 pt-4 pl-4 pr-4 pb-4"}>
					<Controller
						name="codiceFiscale"
						control={control}
						defaultValue=""
						rules={{
							required: true
						}}
						render={({ field: { onChange } }) => (
							<InputConDescrizione
								titolo=""
								nome="codiceFiscale"
								tipo="text"
								placeholder={tipoRicerca.placeholder[2]}
								onChange={(e) => {
									e.target.value = e.target.value.toUpperCase();
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					{errors.codiceFiscale && <div role="alert"><p style={{ color: "red" }} className="my-1">Il campo CF / IVA è obbligatorio</p></div>}
				</div>
				<div className="flex-grow-1 pt-4 pl-4 pr-4 pb-4">
					<Controller
						name="codice"
						control={control}
						defaultValue=""
						rules={{
							required: true
						}}
						render={({ field: { onChange } }) => (
							<InputConDescrizione
								titolo=""
								nome="codice"
								tipo="text"
								placeholder={tipoRicerca.placeholder[1]}
								onChange={onChange}
							/>
						)}
					/>
					{errors.codice && <div role="alert"><p style={{ color: "red" }} className="my-1">Il campo IUV / Codice Avviso è obbligatorio</p></div>}
				</div>
				<div className={isMobileLarge ? "flex-grow-1 pt-4 pl-4 pr-4 pb-4" : "ml-4 mt-2 pt-4 pl-4 pr-4 pb-4"}>
					<Button style={isMobileLarge ? { width: "100%" } : {}} color="primary" type="submit" disabled={!user && !captcha} onClick={() => clearErrors()}>
						Cerca
					</Button>
				</div>
				<br />
			</form>
			<div className="carrello-info-text">
				Inserisci i dati del pagamento
				{!user && " oppure "}
				{!user && (
					<Link style={{ color: "blue" }} to="/autenticazione">
						autenticati
					</Link>
				)}
			</div>
			{!user && <Captcha doneCallback={setCaptcha} captchaRef={captchaRef} />}
		</div>
	);

	return (
		<div>
			<section className="pt-3 pb-4 sezione-consulta" style={{ display: "flex", flexDirection: "column" }}>
				<div className="container">
					<h1>Effettua un pagamento</h1>
					<div className={`boxRicerca ${!isMobileMedium && "p-4"}`}>
						<div className="row">
							<div className="col-12 pl-4 pr-4 pt-4 pb-2">
								<ul className="nav nav-tabs nav-item-list-genSearch ">
									{elementiMenu}
								</ul>
							</div>
						</div>
						<div>
							{elementiRicerca}
						</div>
					</div>
					{/*!expectResultsPagamento && (
						<Typography style={{ marginTop: "50px" }}>
							<h3>Benvenuto su pagoPA Sardegna</h3>
							la piattaforma della Regione Autonoma della Sardegna per i
							pagamenti elettronici
							verso la Regione o gli Enti intermediati.
							<br />
							<br />
							&#x2022; Puoi consultare la lista degli enti che hanno aderito all&apos;iniziativa della Regione
							nella sezione &quot;
							<b>Enti Creditori</b>
							&quot;.
							<br />
							&#x2022; Se vuoi effettuare un pagamento per la Regione o per gli altri Enti, accedi alla
							sezione &quot;
							<b>Paga</b>
							&quot;.
							<br />
							&#x2022; Per verificare lo stato di un pagamento già effettuato accedi alla sezione &quot;
							<b>Storico Pagamenti</b>
							&quot;.
							<br />
							&#x2022; Per visualizzare eventuali posizioni debitorie, accedi alla sezione &quot;
							<b>Home</b>
							&quot; previa autenticazione mediante <em>SPID</em> o <em>CIE</em>.
						</Typography>
					)*/}
				</div>
				{errors?.general && (
					<section className="section section-inset-shadow pb-0 pt-2 pb-5">
						<div className="container p-0">
							<div className="row my-4">
								<div className="col-12 pt-4 pl-4 pr-4 pb-4">{errors?.general?.descrizione}</div>
							</div>
						</div>
					</section>
				)}
				{/*!expectResultsPagamento && !showAllTributi && (
					<>
						<section className="section section-muted pb-0 pt-0">
							<div className="container p-0 pt-0">
								<h4 style={{ paddingTop: "30px" }}>SE PREFERISCI PUOI</h4>
								<div className="row my-4">
									{boxes}
								</div>
							</div>
						</section>
					</>
				)*/}
				{!expectResultsPagamento && !errors?.general && <HomepageCards setShowAllTributi={setShowAllTributi} showAllTributi={showAllTributi} />}
			</section>
			{expectResultsPagamento && (
				<RisultatiRicercaPagamento
					selection={selection}
					addToSelection={addToSelection}
				/>
			)}
			{!expectResultsPagamento && showAllTributi && (
				<RisultatiRicercaPagamento
					selection={selection}
					addToSelection={addToSelection}
				/>
			)}
			<ModaleServizio
				isOpen={isOpenServ != null}
				setIsOpen={() => { setIsOpenServ(null); }}
				paymentData={isOpenServ}
			/>
		</div>
	);
}
