import React from "react";
import { Button } from "design-react-kit";
import { useMediaQuery } from "@mui/material";
import configuration from "../../../configuration";
import useToken from "../../../Hooks/useToken";
import { removeEmptyObjects } from "../../../Utils/Object";

export default function DownloadPaymentNotice({ codiceEnte, iuv, pluginData, disabled, text, all, color }) {
	const isMobileS = useMediaQuery("(max-width: 320px)");
	const jwt = useToken();

	const model3Submit = async () => {
		const response = await fetch(`${configuration.serverAddress}/pagamento/paymentNotice/${codiceEnte}/${iuv}${all ? "/allRate" : ""}`, {
			method: 'POST',
			body: removeEmptyObjects(pluginData) !== null ? JSON.stringify(removeEmptyObjects(pluginData).filter((x) => x != null && Object.keys(x).length > 0)) : null,
			headers: new Headers({ Authorization: `Bearer ${jwt}`, ...configuration.defaultHeaders })
		});

		const [_, filename] = response.headers.get('content-disposition').split('filename=');

		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	return (
		<Button color={color ?? "outline-primary"} onClick={model3Submit} disabled={disabled} style={isMobileS ? { width: "100%" } : {}}>
			{text ?? "Scarica avviso"}
		</Button>
	);
}
