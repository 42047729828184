import {
	FETCH_ALL_ENTI,
	FETCH_ALL_ENTI_SUCCESS,
	FETCH_ALL_TRIBUTI,
	FETCH_ALL_TRIBUTI_SUCCESS,
	FETCH_ALL_STRUTTURE,
	FETCH_ALL_STRUTTURE_SUCCESS,
	FETCH_ALL_ENTI_LOADING, FETCH_ALL_TRIBUTI_LOADING, FETCH_ALL_STRUTTURE_LOADING
} from "./actionTypes";

export const fetchAllEnti = (search) => ({
	type: FETCH_ALL_ENTI,
	search
});

export const fetchAllEntiLoading = () => ({
	type: FETCH_ALL_ENTI_LOADING
});

export const fetchAllEntiSuccess = (enti, error = false) => ({
	type: FETCH_ALL_ENTI_SUCCESS,
	enti,
	error
});

export const fetchAllTributi = (search) => ({
	type: FETCH_ALL_TRIBUTI,
	search
});

export const fetchAllTributiLoading = () => ({
	type: FETCH_ALL_TRIBUTI_LOADING
});

export const fetchAllTributiSuccess = (tributi, error = false) => ({
	type: FETCH_ALL_TRIBUTI_SUCCESS,
	tributi,
	error
});

export const fetchAllStrutture = (search) => ({
	type: FETCH_ALL_STRUTTURE,
	search
});

export const fetchAllStruttureLoading = () => ({
	type: FETCH_ALL_STRUTTURE_LOADING
});

export const fetchAllStruttureSuccess = (strutture, error = false) => ({
	type: FETCH_ALL_STRUTTURE_SUCCESS,
	strutture,
	error
});
