import { useDispatch, useSelector } from "react-redux";
import { getRefreshToken, logout } from "../Store/Authentication";
import { isTokenExpired } from "../Utils/jwt";

export default function useToken() {
	const dispatch = useDispatch();
	const accessToken = useSelector((state) => state.authentication.accessToken);
	const refreshToken = useSelector((state) => state.authentication.refreshToken);
	const userState = useSelector((state) => state.authentication.state);

	if (refreshToken && isTokenExpired(refreshToken)) dispatch(logout());
	if (accessToken && isTokenExpired(accessToken) && userState !== "refreshing") dispatch(getRefreshToken());

	return accessToken;
}
