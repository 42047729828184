/* eslint-disable import/prefer-default-export */

export function selectionArrayToObject(selection) {
	const ret = {};
	for (let i = 0; i < selection.length; i++) {
		const [type, ...code] = selection[i].split('-');
		ret[type] = code.join("-");
	}
	return ret;
}

export function selectionObjectToArray(selection) {
	const ret = [];
	Object.entries(selection).forEach((val) => {
		const [key, value] = val;
		ret.push(`${key}-${value}`);
	});
	return ret;
}
