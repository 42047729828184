import { useState } from 'react';
import { deleteApi } from "../Utils/DeleteApi";
import ConfirmModal from './ConfirmModal';
import useToken from "../../Hooks/useToken";

export default function DeleteItem({
	entityNameSingle, entityNamePlural, query, open,
	items, onDeleted, onError, onCancel, ente
}) {
	const [deleteState, setDeleteState] = useState('idle');

	// - Prendo il token JWT
	const jwt = useToken();

	const deleteConfirmCallback = async () => {
		setDeleteState('loading');

		const result = await deleteApi(query, items, ente, jwt);

		if (result) {
			setDeleteState('idle');
			if (onDeleted) onDeleted();
		} else {
			setDeleteState('idle');
			if (onError) onError();
		}
	};

	return (
		<ConfirmModal
			open={open}
			loading={deleteState === 'loading'}
			title="Conferma eliminazione"
			text={`Sei sicuro di voler continuare eliminando ${items.length} ${items.length > 1 ? entityNamePlural : entityNameSingle}?`}
			onConfirm={deleteConfirmCallback}
			onDeny={onCancel}
			confirmText="Elimina"
		/>
	);
}
