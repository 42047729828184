import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../Components/Common/Breadcrumbs';
import TitleWithSearch from '../Components/Common/TitleBarWithSearch';
import ListaEnti from '../Components/Enti/ListaEnti';

export default function Enti() {
	const [search, setSearch] = useState('');

	const breadcrumbs = [
		{ titolo: 'enti' }
	];

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<TitleWithSearch
				titolo="Enti"
				sottotitolo="Enti creditori attivi su pagoPA Sardegna"
				placeholderRicerca="Inserire la denominazione dell’ente creditore"
				searchChange={(e) => setSearch(e)}
			/>
			<div style={{ minHeight: "700px" }}>
				<ListaEnti searchString={search} />
			</div>
		</>
	);
}
