import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CampoSelect({ value, onChange, campo, className }) {
	return (
		<FormControl variant="standard" sx={{ minWidth: "100%" }}>
			<InputLabel id={`select-${campo.name}`}>{campo.label}</InputLabel>
			<Select id={`select-${campo.name}`} className={`${className} w-100`} variant="standard" label={campo.label} placeholder={campo.label} value={value} onChange={(v) => onChange(v.target.value)}>
				<MenuItem value="">Nessuno</MenuItem>
				{campo.valori.map((v) => (
					<MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
