export default class DataLabel {
	constructor(label, data) {
		this.label = label;
		this.data = data;
	}

	toString() {
		return this.label;
	}

	getData() {
		return this.data;
	}
}
