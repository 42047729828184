/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import NumberFormat from "react-number-format";
import BasePlugin from "../BasePlugin";
import InputConDescrizione from "../../Portal/Components/Common/InputConDescrizione";

export default class ImportoDelegatoPlugin extends BasePlugin {
	/**
	 * Permette di personalizzare la configurazione iniziale del plugin.
	 */
	emptyConfiguration() {
		return {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.amountDelegate.ImportoDelegatoConfiguration",
			nome: "Importo delegato",
			codicePlugin: "IMPORTO_DELEGATO",
			ordine: this.configuration.index,
			descrizione: "Permette di inserire un importo in fase di prepagamento."
		};
	}


	/**
	 * Modifica i dati del form prima che vengano inviati al server.
	 * @param data I dati del form.
	 * @returns {*}
	 */
	backofficePaymentSubmit({ formValues, pluginData }) {
		return {
			formValues,
			pluginData: [...pluginData, {
				codicePlugin: "IMPORTO_DELEGATO",
				"@class": "it.ras.pagopa.shared.plugins.pagamenti.amountDelegate.ImportoDelegatoData"
			}]
		};
	}

	/**
	 * Componente chiamato durante il rendering della pagina di riepilogo.
	 * compilare nei pagamenti spontanei (Portale).
	 * @param onCheckout Chiamata quando si sta premendo il bottone per andare alla pagina Checkout.
	 * @param onReceiptRequest Chiamata quando si sta premendo il
	 * bottone per scaricare l'avviso di pagamento.
	 * @returns {JSX.Element}
	 */
	paymentSummary({ debit, config, data, path, viewOnly }) {
		const { setValue, watch } = useFormContext();
		const [displayValue, setDisplayValue] = useState(debit.pendenze[0].dettagli[0].importo);

		const setNewValue = (importo) => setValue(path, {
			"@class": "it.ras.pagopa.shared.plugins.pagamenti.amountDelegate.ImportoDelegatoData",
			codicePlugin: "IMPORTO_DELEGATO",
			importo
		});

		useEffect(() => { setNewValue(debit.pendenze[0].dettagli[0].importo); }, []);

		return viewOnly ? <></> : (
			<div className="mt-3">
				<h3>Importo a carico dell&apos;utente</h3>
				<div className="col-12 col-md-9 pt-3">
					<NumberFormat
						onValueChange={({ formattedValue, value: nv }, sourceInfo) => {
							setDisplayValue(formattedValue);
							setValue("debito.pendenze.0.dettagli.0.importo", parseFloat(nv));
							setValue("selectedRata.dettagli.0.importo", parseFloat(nv));
							setNewValue(parseFloat(nv));
						}}
						value={displayValue}
						tipo="text"
						min={0.01}
						fixedDecimalScale
						customInput={InputConDescrizione}
						prefix="€ "
						decimalScale={2}
						decimalSeparator=","
						thousandSeparator="."
					/>
				</div>
			</div>
		);
	}
}
