import React, { useState } from "react";
import {
	AccordionSummary,
	AccordionDetails,
	Accordion,
	IconButton,
	MenuItem,
	Select,
	Typography
} from "@mui/material";

import RegolaIf from "./Regole/RegolaIf";
import AzioneSetValore from "./Azioni/AzioneSetValore";
import AzioneSetVisible from "./Azioni/AzioneSetVisible";
import Compare from "./Condizioni/Compare";
import ConnettoreLogico from "./Condizioni/ConnettoreLogico";
import AzioneSetValoreMin from "./Azioni/AzioneSetValoreMin";
import AzioneSetLabel from "./Azioni/AzioneSetLabel";
import AzioneSetRequired from "./Azioni/AzioneSetRequired";
import AzioneSetSuggestion from "./Azioni/AzioneSetSuggestion";
import AzioneHideSelectOptions from "./Azioni/AzioneHideSelectOptions";
import AzioneSetReadonly from "./Azioni/AzioneSetReadonly";
import AzioneSetTipoDebitore from "./Azioni/AzioneSetTipoDebitore";
import TimeCondition from "./Condizioni/TimeCondition";
import AzioneSetCE from "./Azioni/AzioneSetCE";

export default function EditorRegole({ value = [], onChange, currentField, campi }) {
	const [regola, setRegola] = useState("RegolaIf");
	const [expanded, setExpanded] = useState(false);

	const meta = {
		regole: {
			RegolaIf: {
				component: RegolaIf,
				label: "Regola IF",
				value: "RegolaIf",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.regole.RegolaIf",
					condizione: {
						"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.Compare",
						operatore: "Uguale",
						valore: ""
					},
					azioni: []
				}
			}
		},
		condizioni: {
			Compare: {
				component: Compare,
				label: "Compara",
				value: "Compare",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.Compare",
					operatore: "Uguale",
					valore: ""
				}
			},
			TimeCondition: {
				component: TimeCondition,
				label: "Condizione temporale",
				value: "TimeCondition",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.TimeCondition",
					operatore: "Uguale",
					valore: ""
				}
			},
			ConnettoreLogico: {
				component: ConnettoreLogico,
				label: "Connettore logico",
				value: "ConnettoreLogico",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.ConnettoreLogico",
					connettore: "Or",
					condizione1: {
						"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.Compare",
						operatore: "Uguale",
						valore: ""
					},
					condizione2: {
						"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.condizioni.Compare",
						operatore: "Uguale",
						valore: ""
					}
				}
			}
		},
		azioni: {
			AzioneSetValore: {
				component: AzioneSetValore,
				label: "Setta un valore",
				value: "AzioneSetValore",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetValore",
					nomeCampoTarget: "",
					valore: ""
				}
			},
			AzioneSetVisible: {
				component: AzioneSetVisible,
				label: "Setta la visibilità",
				value: "AzioneSetVisible",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetVisible",
					nomeCampoTarget: "",
					valore: true
				}
			},
			AzioneSetValoreMin: {
				component: AzioneSetValoreMin,
				label: "Setta il valore minimo",
				value: "AzioneSetValoreMin",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetValoreMin",
					nomeCampoTarget: "",
					valore: 0
				}
			},
			AzioneSetReadonly: {
				component: AzioneSetReadonly,
				label: "Setta la proprietà di sola lettura",
				value: "AzioneSetReadonly",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetReadonly",
					nomeCampoTarget: "",
					valore: true
				}
			},
			AzioneSetLabel: {
				component: AzioneSetLabel,
				label: "Setta il nome del campo",
				value: "AzioneSetLabel",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetLabel",
					nomeCampoTarget: "",
					valore: ""
				}
			},
			AzioneSetCE: {
				component: AzioneSetCE,
				label: "Setta il capitolo entrata",
				value: "AzioneSetCE",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetCE",
					nomeCampoTarget: "",
					valore: ""
				}
			},
			AzioneSetRequired: {
				component: AzioneSetRequired,
				label: "Setta l'obbligatorietà del campo",
				value: "AzioneSetRequired",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetRequired",
					nomeCampoTarget: "",
					valore: true
				}
			},
			AzioneSetSuggestion: {
				component: AzioneSetSuggestion,
				label: "Setta il suggerimento del campo",
				value: "AzioneSetSuggestion",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetSuggestion",
					nomeCampoTarget: "",
					valore: ""
				}
			},
			AzioneHideSelectOptions: {
				component: AzioneHideSelectOptions,
				label: "Setta la visibilità degli elementi di una Select",
				value: "AzioneHideSelectOptions",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneHideSelectOptions",
					nomeCampoTarget: "",
					valore: []
				}
			},
			AzioneSetTipoDebitore: {
				component: AzioneSetTipoDebitore,
				label: "Setta i valori ammissibili del campo Debitore",
				value: "AzioneSetTipoDebitore",
				dv: {
					"@class": "it.ras.pagopa.shared.plugins.pagamenti.campi.metadata.azioni.AzioneSetTipoDebitore",
					nomeCampoTarget: "",
					valore: ""
				}
			}
		}
	};

	return (
		<div>
			{
				// eslint-disable-next-line
				(value ?? []).map((regola, i) => {
					const ruleName = regola["@class"].split(".").pop();
					const ruleMeta = meta.regole[ruleName];
					return (
						<Accordion
							key={`regola-${i + 1}`}
							expanded={expanded === i}
							onChange={(event, isExpanded) => setExpanded(isExpanded ? i : false)}
						>
							<AccordionSummary>
								<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", width: '250px', flexShrink: 0 }}>
									<IconButton size="small" className="mr-2" onClick={() => onChange(value.filter((_, c) => c !== i))}>
										<i className="fas fa-fw fa-trash" />
									</IconButton>
									<b className="mr-3">{ruleMeta.label}</b>
								</Typography>
								<Typography noWrap sx={{ overflow: "hidden", textOverflow: "ellipsis", color: 'text.secondary', width: "500px" }}>Premere per visualizzare i dettagli.</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<ruleMeta.component
									value={regola}
									currentField={currentField}
									meta={meta}
									campi={campi}
									onChange={(curr) => onChange(value.map((old, c) => (c === i ? curr : old)))}
								/>
							</AccordionDetails>
						</Accordion>
					);
				})
			}
			<div className="mt-4">
				<Select style={{ width: "200px" }} variant="standard" label="Tipo regola" value={regola} onChange={(v) => setRegola(v.target.value)}>
					{
						Object.values(meta.regole).map((v) => (
							<MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>
						))
					}
				</Select>
				<IconButton
					onClick={() => {
						onChange([...(value ?? []), meta.regole[regola].dv]);
					}}
				>
					<i className="fas fa-fw fa-plus-circle" />
				</IconButton>
			</div>
		</div>
	);
}
