import { Chip, Divider, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { Checkbox } from "antd";

export default function EditableSelectList({ value, onChange, className }) {
	const [currLabel, setCurrLabel] = useState("");
	const [currValue, setCurrValue] = useState("");
	const [currHidden, setCurrHidden] = useState(false);

	return (
		<div className={`d-inline-flex flex-column ${className}`}>
			<div className="d-inline-flex flex-row w-100">
				<TextField value={currValue} onChange={(v) => setCurrValue(v.target.value)} label="Valore" variant="standard" className="w-100" />
				<TextField value={currLabel} onChange={(v) => setCurrLabel(v.target.value)} label="Label" variant="standard" className="ml-3 w-100" />
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", marginLeft: "20px", marginRight: "20px" }}>
					<b>Nascosto</b>
					<input
						type="checkbox"
						checked={currHidden}
						onChange={(v) => setCurrHidden(v.target.checked)}
						label="Hidden"
						style={{ transform: "scale(1.5)" }}
					/>
				</div>
				<IconButton onClick={() => onChange([...value, { label: currLabel, value: currValue, hidden: currHidden }])}><i className="fas fa-fw fa-plus-circle" /></IconButton>
			</div>
			<Divider className="mt-4 mb-3" />
			<div style={{ display: "flex", flexWrap: "wrap", columnGap: "5px", rowGap: "5px" }}>
				{
					value.map((v) => (
						<Chip label={`${v.value} : ${v.label} - ${v.hidden ? "Nascosto" : "Visibile"}`} onDelete={() => onChange(value.filter((x) => x.value !== v.value))} />
					))
				}
			</div>
		</div>
	);
}
