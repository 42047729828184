import { Link } from 'react-router-dom';

export default function BoxHomepage(props) {
	const {
		testoRiga1,
		testoRiga2,
		link,
		testoLink,
		tooltip
	} = props;

	return (
		<div className="col-12 col-md-12 col-lg-6 p-3">
			<div className="box-homepage-bottone-card">
				<div>{testoRiga1}</div>
				<div className="box-homepage-bottone-card-titolo">{testoRiga2}</div>
				<div style={{ marginTop: "60px" }} />
				<div className="box-homepage-bottone-card-bottone">
					<Link className="btn btn-primary" to={link} title={tooltip} aria-label={tooltip}>
						{testoLink}
					</Link>
				</div>
			</div>
		</div>
	);
}
