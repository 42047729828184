import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import HeaderProfilo from './HeaderProfilo';
import InputConDescrizione from "../Common/InputConDescrizione";
import { useFetch } from "../../../Hooks/useFetch";
import configuration from "../../../configuration";
import handleErrors from "../../../Backoffice/Utils/HandleErrors";
import useToken from "../../../Hooks/useToken";

export default function DatiAnagrafici() {
	const [editing, setEditing] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const jwt = useToken();

	// - Effettuo la chiamata api per ricevere le informazioni dell'utente
	const { data: accountInfo } = useFetch('/user/info', null, undefined, undefined, refresh);

	const { control, handleSubmit, formState, setError } = useForm({ values: accountInfo?.intestatario });

	const submitFunction = async (data) => {
		const response = await fetch(`${configuration.serverAddress}/user/anagrafica`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({ Authorization: `Bearer ${jwt}`, 'Content-Type': 'application/json' })
		});

		if (response.status === 200) {
			setRefresh((r) => r + 1);
			setEditing(false);
		}

		if (response.status >= 400) {
			const error = await response.json();
			if (error.dettaglio !== undefined) {
				error.dettaglio.forEach((errore) => setError(errore.field.split('.').pop(), {
					description: errore.description }));
			} else handleErrors(error, setError);
		}
	};

	const isTablet = useMediaQuery("(max-width: 768px)");

	return (
		<>
			<HeaderProfilo menuAttivo="dati-anagrafici" />
			<section id="profilo-utente" className="bg-lightblue pb-4 sezione-consulta">
				<div className="container">
					{
						!isTablet && (
							<h3>Dati Anagrafici</h3>
						)
					}
					<div className="row bg-white pt-4 pr-4 pl-4">
						{accountInfo?.intestatario.ragioneSociale
							? (
								<div className="col-lg-4 col-md-4">
									<Controller
										control={control}
										render={({ field: { value, onChange }, fieldState: { error } }) => (
											<InputConDescrizione
												titolo="Intestatario"
												tipo="text"
												readonly={!editing}
												onChange={onChange}
												value={value}
											/>
										)}
										name="ragioneSociale"
									/>
								</div>
							) : (
								<>
									<div className="col-lg-4 col-md-4">
										<Controller
											control={control}
											render={({ field: { value, onChange }, fieldState: { error } }) => (
												<InputConDescrizione
													titolo="Nome"
													tipo="text"
													readonly
													onChange={onChange}
													value={value}
												/>
											)}
											name="nome"
										/>
									</div>
									<div className="col-lg-4 col-md-4">
										<Controller
											control={control}
											render={({ field: { value, onChange }, fieldState: { error } }) => (
												<InputConDescrizione
													titolo="Cognome"
													tipo="text"
													readonly
													onChange={onChange}
													value={value}
												/>
											)}
											name="cognome"
										/>
									</div>
								</>
							)}
						<div className="col-lg-4 col-md-4">
							<InputConDescrizione
								titolo="Codice Fiscale"
								readonly
								tipo="text"
								nome="codfis"
								value={accountInfo?.intestatario.codiceFiscale
									?? accountInfo?.intestatario.partitaIva ?? ''}
								obbligatorio
							/>
						</div>

					</div>
					<div className="row bg-white pt-4 pr-4 pl-4">
						<div className="col-12 pb-2">
							<h4>Indirizzo di residenza</h4>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Nazione"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="nazione"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Provincia"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="provincia"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Comune"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="comune"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Indirizzo"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="indirizzo"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Numero civico"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="numeroCivico"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="CAP"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="cap"
							/>
						</div>
					</div>
					<div className="row bg-white p-4" style={{ justifyContent: "flex-start" }}>
						<div className="col-12 pb-2">
							<h4>Recapiti</h4>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Cellulare"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="cellulare"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="Telefono"
										tipo="text"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="telefono"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="E-mail"
										tipo="email"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="email"
							/>
						</div>
						<div className="col-lg-4 col-md-4">
							<Controller
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<InputConDescrizione
										titolo="PEC"
										tipo="email"
										readonly={!editing}
										onChange={onChange}
										errore={error?.description}
										value={value}
									/>
								)}
								name="pec"
							/>
						</div>
					</div>
					<div className="row bg-white p-4 text-right">
						<div className="col-12 pb-2">
							{editing && (
								<button
									type="button"
									className="btn btn-outline-primary"
									title="Salva"
									onClick={handleSubmit(submitFunction)}
								>
									Salva le modifiche
								</button>
							)}
							{!editing && (
								<button
									type="button"
									className="btn btn-outline-primary"
									title="Modifica"
									onClick={() => {
										setEditing(true);
									}}
								>
									Modifica
								</button>
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
