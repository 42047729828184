import React from "react";
import { TextField } from "@mui/material";

export default function CampoNumerico({ value, onChange, campo }) {
	return (
		<TextField
			variant="standard"
			style={{ minWidth: "300px", flexGrow: 1, flexShrink: 1 }}
			type="number"
			label="Valore numerico"
			InputProps={{ inputProps: { min: campo.min, max: campo.max } }}
			value={value}
			onChange={(v) => onChange(v.target.value)}
		/>
	);
}
