/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';

export default function Select(props) {
	const {
		nome,
		onChange,
		scelte,
		valoreSelezionato,
		titolo,
		obbligatorio,
		descrizione,
		errore,
		elementiNascosti
	} = props;

	const [isOpen, setIsOpen] = useState(false);
	const button = useRef(null);

	const opzioniDropdown = Object.keys(scelte).map((key, i) => {
		const sSel = `${valoreSelezionato}`;
		if (sSel === key) {
			return (
				<li key={`select-${nome}-${key}`} className="selected active">
					<a onClick={() => { onChange(key); }} role="option" className="dropdown-item active selected" tabIndex={i} aria-selected="true">
						<span className="text">{scelte[key]}</span>
					</a>
				</li>
			);
		}
		return (
			<li key={`select-${nome}-${key}`} hidden={elementiNascosti?.length > 0 ? elementiNascosti[i] : false}>
				<a onClick={() => { onChange(key); }} role="option" className="dropdown-item" tabIndex={i}><span className="text">{scelte[key]}</span></a>
			</li>
		);
	});

	useEffect(() => window.addEventListener('click', (ev) => {
		if (button.current && button.current.contains(ev.target)) {
			setIsOpen(!isOpen);
		} else {
			setIsOpen(false);
		}
	}), []);

	return (
		<div className="dropdown bootstrap-select form-select">
			{titolo && (
				<h5>
					{titolo}
					{obbligatorio && ' *'}
				</h5>
			)}
			<button
				ref={button}
				onClick={() => setIsOpen(!isOpen)}
				type="button"
				tabIndex="-1"
				id={`select-${nome}-id`}
				className="btn dropdown-toggle btn-light"
				style={{ background: "white" }}
				data-toggle="dropdown"
				aria-owns="bs-select-1"
				aria-haspopup="listbox"
				aria-expanded="false"
				data-id="paginazione-per-pagina"
				title={scelte[valoreSelezionato]}
			>
				<span className="filter-option">
					<span className="filter-option-inner">
						<span className="filter-option-inner-inner">{scelte[valoreSelezionato]}</span>
					</span>
				</span>
			</button>
			<div className={classNames({ 'dropdown-menu': true, show: isOpen })} style={{ overflowY: "auto", maxHeight: "400px" }}>
				<div className="inner show" role="listbox" id="bs-select-1" tabIndex="-1" aria-activedescendant="bs-select-1-1">
					<ul className="dropdown-menu inner show" role="presentation">
						{opzioniDropdown}
					</ul>
				</div>
			</div>
			<p style={errore && { color: "red" }}><i>{errore ?? descrizione}</i></p>
		</div>
	);
}
