/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import configuration from "../../configuration";

const initialState = [];

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		addToCart: (state, { payload: debito }) => {
			if (state.length < 5) state.push(debito);
		},
		removeFromCart: (state, { payload: pendenza }) => {
			const index = state.findIndex((elem) => elem.iuv === pendenza.iuv
				&& elem.ente.codiceEnte === pendenza.ente.codiceEnte);
			if (index >= 0) state.splice(index, 1);
		}
	}
});

export const { addToCart, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;
