export default function ProfilobottoneCard(props) {
	const {
		tipo,
		icona,
		titolo
	} = props;

	return (
		<div className="profilo-bottone-card">
			<div className="profilo-bottone-card-icona">
				<i className={icona} />
			</div>
			<div className="profilo-bottone-card-tipo">{tipo}</div>
			<div className="profilo-bottone-card-titolo">{titolo}</div>
		</div>
	);
}
