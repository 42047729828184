import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import { useFetch } from "../../../Hooks/useFetch";

export default function Operatori({ control, operatori }) {
	return operatori ? (
		<Controller
			name="operatori"
			defaultValue={[]}
			control={control}
			onChange={([, d]) => d}
			render={({ field: { onChange, value } }) => (
				<Autocomplete
					filterSelectedOptions
					multiple
					isOptionEqualToValue={(option, v) => option.id === v.id}
					options={operatori}
					getOptionLabel={(option) => `${option.intestatario.codiceFiscale} | ${option.intestatario.nome} ${option.intestatario.cognome}`}
					value={value || ''}
					onChange={(e, d) => onChange(d)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="standard"
							label="Operatori associati"
						/>
					)}
				/>
			)}
		/>
	) : <></>;
}
