/* eslint-disable import/prefer-default-export */
import { all } from "redux-saga/effects";
import { ricercaWatchers } from "./Ricerca";

// Importare eventuali altri watchers ed aggiungerli qua sotto

const allSagas = [ricercaWatchers()];

export const createSagas = () => function* combineSagas() {
	yield all([...allSagas]);
};
