/* eslint-disable no-bitwise */
import { useRowSelection } from "react-data-grid";
import { createContext, useContext, useEffect, useState } from "react";
import { IconButton } from "@mui/material";

export const EXPAND_COLUMN_KEY = 'expand-row';

const ExpandRowContext = createContext({
	dispatch: () => {}
});

export const ExpandRowProvider = ExpandRowContext.Provider;

function useExpandRow() {
	return useContext(ExpandRowContext);
}

function SelectFormatter({ row }) {
	const { dispatch } = useExpandRow();

	if (row.children && row.children.length > 0) {
		return (
			<IconButton onClick={() => dispatch({ id: row.id, type: 'toggleSubRow' })}>
				<i className={`fas fa-fw fa-${row.isExpanded ? 'angle-down' : 'angle-right'}`} />
			</IconButton>
		);
	}

	return <></>;
}

export const DataGridExpandColumn = {
	key: EXPAND_COLUMN_KEY,
	name: '',
	width: 54,
	minWidth: 54,
	maxWidth: 54,
	resizable: false,
	draggable: false,
	frozen: true,
	headerRenderer(props) {
		const { dispatch } = useExpandRow();
		const [open, setOpen] = useState(null);

		useEffect(() => {
			if (open != null) dispatch({ type: "toggleAll", value: open });
		}, [open]);

		return (
			<IconButton onClick={() => setOpen(open === null ? true : !open)}>
				<i className={`fas fa-fw fa-${open ? 'angle-down' : 'angle-right'}`} />
			</IconButton>
		);
	},
	formatter(props) {
		return <SelectFormatter {...props} />;
	}
};
