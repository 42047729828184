import React, { useState } from "react";
import { Box, Divider, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TransferList from "./Forms/TansferList";
import configuration from "../../configuration";
import handleErrors from "../Utils/HandleErrors";
import useToken from "../../Hooks/useToken";
import { removeEmptyObjects } from "../../Utils/Object";

export default function Export({
	open, onDeny, initialSelected, columns,
	queryFilters, totalResults, type, onSuccess
}) {
	const [loading, setLoading] = useState(false);
	const [selected, setSelected] = useState(initialSelected);
	const [submitState, setSubmitState] = useState('idle');
	const [error, setError] = useState(null);
	const [divider, setDivider] = useState("PV");
	const jwt = useToken();

	const onSubmit = (filters) => () => {
		setSubmitState('loading');

		fetch(`${configuration.serverAddress}/export/prenotazione/${type.apiName}`, {
			method: 'POST',
			body: JSON.stringify(removeEmptyObjects({
				tipo: type.name,
				parametri: {
					"@class": type.path,
					campi: selected.map((f) => f.getData()).filter((f) => !f.startsWith("PLUGIN|")),
					campiPlugin: selected.map((f) => f.getData()).filter((f) => f.startsWith("PLUGIN|")),
					filtri: {
						...filters,
						page: null,
						pageSize: null,
						limit: null
					},
					allFields: false,
					delimiter: divider
				}
			})),
			headers: new Headers({
				Authorization: `Bearer ${jwt}`,
				...configuration.defaultHeaders
			})
		}).then(async (response) => {
			if (response.status === 201) {
				setSubmitState('idle');
				if (onSuccess) onSuccess();
			}
			if (response.status >= 400) {
				throw await response.json();
			}
		}).catch((err) => {
			setSubmitState('idle');
			handleErrors(err, setError);
		});
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 800,
		height: '95%',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	return (
		<Modal
			open={open}
			onClose={onDeny}
		>
			<Box sx={style}>
				<h2>Export CSV</h2>
				<Divider />
				{totalResults > 10000
					? (
						<>
							<Typography id="modal-modal-description" sx={{ mt: 2 }}>
								{`Si sta cercando di esportare ${totalResults} elementi, il numero massimo consentito è di 10.000 debiti o pagamenti, è necessario applicare dei filtri di ricerca più specifici.`}
							</Typography>
							<br />
							{/* eslint-disable-next-line */}
							<div align="center" className="mt-5 mb-1">
								<LoadingButton onClick={onDeny} variant="outlined" className="ml-2">Annulla</LoadingButton>
							</div>
						</>
					) : (
						<>
							<Typography id="modal-modal-description" sx={{ mt: 2 }}>
								{`Verranno messi in coda per l'export ${totalResults} debiti in formato CSV. L'export conterrà una riga per ogni dettaglio di ciascun debito.`}
							</Typography>
							<br />
							<br />
							<TransferList
								allValues={columns}
								selected={selected}
								onChange={setSelected}
							/>
							{/* eslint-disable-next-line */}
							<div align="center" className="mt-4 mb-3">
								<FormControl variant="standard" style={{ width: "200px", textAlign: "left" }}>
									<InputLabel>Delimitatore</InputLabel>
									<Select
										label="Delimitatore"
										style={{ width: "200px" }}
										value={divider}
										onChange={(e) => setDivider(e.target.value)}
									>
										<MenuItem value="PV">Punto e virgola</MenuItem>
										<MenuItem value="TB">Tab</MenuItem>
										<MenuItem value="VI">Virgola</MenuItem>
										<MenuItem value="PI">Barra Verticale</MenuItem>
									</Select>
								</FormControl>
							</div>
							{/* eslint-disable-next-line */}
							<div align="center" className="mt-5 mb-1">
								<LoadingButton onClick={onSubmit(queryFilters)} loading={loading} variant="contained" className="mr-2">Continua</LoadingButton>
								<LoadingButton onClick={onDeny} variant="outlined" className="ml-2">Annulla</LoadingButton>
							</div>
						</>
					)
				}
			</Box>
		</Modal>
	);
}
